import * as React from 'react'

import { styled } from 'styled-components'

interface Props {
    error?: string
    align?: 'left' | 'right'
}

const FormError = ({ error, align = 'right' }: Props) => {
    return <ErrorContainer align={align}>{error}</ErrorContainer>
}

export default FormError

const ErrorContainer = styled.div<{ align: 'right' | 'left' }>`
    color: red;
    font-size: 14px;
    line-height: 14px;
    text-align: ${(props) => props.align};
    font-style: italic;
    height: 15px;
    margin-top: 10px;
`
