import * as React from 'react'

import styled from 'styled-components'
import { CMPNT_HEIGHT, INNER_SHADOW } from '../utils/constant'

import FormLabel from './FormLabel'
import FormError from './FormError'
import Icon from './Icon'
import { isMobile } from 'react-device-detect'

interface Props {
    value: string | undefined
    setValue: (v: string) => void
    options: { key: string; label: string }[]
    placeholder?: string
    label?: string
    error?: string
}

const Select = ({
    options,
    value,
    placeholder = 'Sélectionner une valeur',
    setValue,
    label,
    error,
}: Props) => {
    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            <InputContainer>
                <SelectContainer isDefault={!value} onChange={(e) => setValue(e.target.value)}>
                    {!value && <option>{placeholder}</option>}
                    {options.map((opt) => (
                        <option value={opt.key} selected={opt.key === value}>
                            {opt.label}
                        </option>
                    ))}
                </SelectContainer>
            </InputContainer>
            <FormError error={error} />
        </ComponentContainer>
    )
}

export default Select

const ComponentContainer = styled.div`
    flex-direction: column;
    display: flex;
    margin: 5px 0;
`
const InputContainer = styled.div`
    border: 5px solid green;
    padding: 0 20px;
    padding-right: 30px;
    border: none;
    outline: none;
    background-color: #eee;
    ${INNER_SHADOW}

    border-radius: ${CMPNT_HEIGHT * 0.25}px;
    height: ${CMPNT_HEIGHT}px;
    width: -webkit-fill-available;

    display: flex;
    align-items: center;
`
const SelectContainer = styled.select<{ isDefault: boolean }>`
    height: ${CMPNT_HEIGHT}px;
    width: -webkit-fill-available;

    background-color: transparent;
    font-size: 16px;
    outline: none;
    border: none;

    color: ${(props) => (props.isDefault ? '#757575' : '#000')};
`
