import * as React from 'react'
import styled from 'styled-components'
import NoItem from '../NoItem'
import CityMap from '../CityMap'
import { LatLng } from 'leaflet'
import { isMobile } from 'react-device-detect'

interface Props {
    arrivals: Arrival[]
    end: City
}

const StepArrivals = ({ arrivals, end }: Props) => {
    return (
        <Container>
            <ArrivalList>
                {arrivals.length === 0 ? (
                    <NoItem />
                ) : (
                    arrivals.map((arrival, index) => (
                        <ArrivalCard key={arrival.team.id}>
                            <ArrivalLine>
                                <ArrivalColumn>
                                    <ArrivalIndex>#{index + 1}</ArrivalIndex>
                                    <TeamMembers>
                                        {arrival.team.users.map((user) => (
                                            <UserCard key={user.id}>
                                                <UserAvatar src={user.picture} alt={user.pseudo} />
                                                <UserName>{user.pseudo}</UserName>
                                            </UserCard>
                                        ))}
                                    </TeamMembers>
                                    <ArrivalDate>
                                        {new Date(arrival.arrivalDate).toLocaleString()}
                                    </ArrivalDate>
                                </ArrivalColumn>
                                <ArrivalColumn isMap>
                                    <ArrivalLocation>
                                        <CityMap
                                            jsonFile={end.jsonFile}
                                            userLocation={
                                                {
                                                    lat: arrival.latitude,
                                                    lng: arrival.longitude,
                                                } as LatLng
                                            }
                                        />
                                    </ArrivalLocation>
                                </ArrivalColumn>
                            </ArrivalLine>
                        </ArrivalCard>
                    ))
                )}
            </ArrivalList>
        </Container>
    )
}

export default StepArrivals

const Container = styled.div`
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`
const ArrivalList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
`
const ArrivalCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
`
const ArrivalIndex = styled.span`
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff6b6b;
`
const TeamInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`
const TeamMembers = styled.div`
    display: flex;
    gap: 10px;
`
const UserCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const UserAvatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`
const UserName = styled.span`
    font-size: 0.9rem;
    color: #333;
`
const ArrivalDate = styled.p`
    font-size: 1rem;
    color: #666;
`
const ArrivalLocation = styled.div`
    height: 100%;
    width: 100%;
`
const ArrivalLine = styled.div`
    display: flex;
    ${isMobile && 'flex-direction: column;'}
`
const ArrivalColumn = styled.div<{ isMap?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${isMobile ? 'justify-content: space-around;' : 'justify-content: center; align-items: center;'}
    ${(props) => !isMobile && props.isMap && 'flex: 2;'}
`
