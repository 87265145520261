import * as React from 'react'

import { styled } from 'styled-components'

import Lottie from 'react-lottie'

interface Props {}

const NOTFOUND_SIZE = 300

const NotFound = ({}: Props) => {
    return (
        <Container>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: require('../assets/lottie/404.json'),
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                height={NOTFOUND_SIZE}
                width={NOTFOUND_SIZE}
            />
        </Container>
    )
}

export default NotFound

const Container = styled.div`
    height: 100%;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`
