import * as React from 'react'

import { styled } from 'styled-components'

interface Props {
    label: string
}

const FormLabel = ({ label }: Props) => {
    return (
        <Container>
            <Label>{label}</Label>
        </Container>
    )
}

export default FormLabel

const Container = styled.div`
    text-decoration: underline;
    margin: 5px auto 5px 0;
`
const Label = styled.div`
    font-weight: 900;
    color: #000;
`
