import { isMobile } from 'react-device-detect'

const HEADER_HEIGHT = 75
const MENU_HEIGHT = 80
const OUTER_SHADOW = 'box-shadow: 1px 1px 5px #7777;'
const INNER_SHADOW = 'box-shadow: inset 1px 1px 3px #aaa6;'
const CMPNT_HEIGHT = 45
const CMPNT_RADIUS = 5

const MAIN_COLOR = '#1F1F1F'
const PRIMARY_COLOR = '#3A4BB1'
const SECONDARY_COLOR = '#f72585'
const TERCARY_COLOR = '#560bad'

const TEMPORARY_CITY: City = {
    id: 'unknown_city',
    name: '',
    picture: '/unknown_city.jpg',
    jsonFile: '',
    startDate: '',
    journeyId: '',
}

const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = seconds % 60
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
}

const STEPS_RESPONSIVE = isMobile
    ? {
          whatever: {
              breakpoint: { max: 4000, min: 0 },
              items: 1,
          },
      }
    : {
          whatever: {
              breakpoint: { max: 4000, min: 0 },
              items: 2,
          },
      }

const CITIES_RESPONSIVE = {
    extraSuperLargeDesktop: {
        breakpoint: { max: 7000, min: 1300 },
        items: 6,
    },
    superLargeDesktop: {
        breakpoint: { max: 1300, min: 1100 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 1100, min: 900 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 900, min: 700 },
        items: 3,
    },
    mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 2,
    },
}

export {
    HEADER_HEIGHT,
    MENU_HEIGHT,
    OUTER_SHADOW,
    INNER_SHADOW,
    CMPNT_HEIGHT,
    CMPNT_RADIUS,
    MAIN_COLOR,
    PRIMARY_COLOR,
    SECONDARY_COLOR,
    TERCARY_COLOR,
    STEPS_RESPONSIVE,
    CITIES_RESPONSIVE,
    TEMPORARY_CITY,
    formatTime,
}
