import * as React from 'react'
import useApi from '../utils/webservices/useApi'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import NotFound from './NotFound'
import styled from 'styled-components'
import CityMap from '../components/CityMap'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'
import { isMobile } from 'react-device-detect'
import NoItem from '../components/NoItem'

const City = () => {
    const params = useParams()
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [city, setCity] = React.useState<City | undefined>()

    React.useEffect(() => {
        if (params.cityId) {
            webservices.cities
                .get(params.cityId)
                .then((c) => {
                    setCity(c)
                    setStatus('fetched')
                })
                .catch(() => setStatus('error'))
        } else {
            setStatus('error')
        }
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!city || status === 'error') {
        return <NoItem />
    }

    const MAP_HEIGHT = 300

    return (
        <Container>
            <CityName>{city.name}</CityName>
            <CityImage src={city.picture} alt={city.name} />
            <CityDate>Début : {new Date(city.startDate).toLocaleDateString()}</CityDate>
            <MapContainer>
                <CityMap jsonFile={city.jsonFile} />
            </MapContainer>
        </Container>
    )
}

export default City

const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const CityName = styled.h1`
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
`

const CityImage = styled.img`
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`

const CityDate = styled.p`
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
`

const MapContainer = styled.div`
    width: 100%;
    overflow: hidden;
    max-width: 800px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`
