import * as React from 'react'
import useApi from '../utils/webservices/useApi'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import NotFound from './NotFound'

const Challenge = () => {
    const params = useParams()
    const webservices = useApi()

    const [challenge, setChallenge] = React.useState<Challenge | undefined>()
    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    React.useEffect(() => {
        if (params.challengeId) {
            webservices.challenges
                .get(params.challengeId)
                .then((c) => {
                    setChallenge(c)
                    setStatus('fetched')
                })
                .catch(() => setStatus('error'))
        } else {
            setStatus('error')
        }
    }, [])

    if (status === 'loading') {
        return (
            <div>
                <Loading type="transparent" />
            </div>
        )
    }

    if (!challenge) {
        return <NotFound />
    }

    return (
        <div>
            <div>{challenge.title}</div>
            <div dangerouslySetInnerHTML={{ __html: challenge.description }} />
            <div>{challenge.startDate}</div>
            <img src={challenge.picture} />
        </div>
    )
}

export default Challenge
