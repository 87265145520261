import * as React from 'react'
import { useAlert } from 'react-alert'

const ErrorLogger = (error: any) => {
    const Alert = useAlert()
    Alert.console.error('Un problème est survenu...')
    console.log('ERREUR : ', error)
}

export default ErrorLogger
