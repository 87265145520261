import * as React from 'react'
import useApi from '../utils/webservices/useApi'

import Loading from '../components/Loading'
import NoItem from '../components/NoItem'
import styled from 'styled-components'
import { MAIN_COLOR, OUTER_SHADOW } from '../utils/constant'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

const Contents = () => {
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [contents, setContents] = React.useState<ContentObject[]>([])

    React.useEffect(() => {
        webservices.journeys
            .getContents()
            .then((c) => {
                setContents(c)
                setStatus('fetched')
            })
            .catch(() => setStatus('error'))
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (contents.length === 0 || status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <ContentGrid>
                {contents.map((content) => (
                    <ContentCard
                        key={content.id}
                        onClick={() => navigate(`/contents/${content.id}`)}>
                        <ContentImage
                            src={content.picture || 'login_background.jpeg'}
                            alt={content.title}
                        />
                        <ContentTitle>{content.title}</ContentTitle>
                    </ContentCard>
                ))}
            </ContentGrid>
        </Container>
    )
}

export default Contents

const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const ContentGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
`

const ContentCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        transform: translateY(-5px);
    }
`

const ContentImage = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
`

const ContentTitle = styled.h2`
    font-size: 1.2rem;
    color: #333;
    margin-top: 10px;
    text-align: center;
`
