import * as React from 'react'

import { styled } from 'styled-components'

import { CMPNT_HEIGHT, INNER_SHADOW } from '../utils/constant'
import FormLabel from './FormLabel'
import FormError from './FormError'
import { format } from 'date-fns'

import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import Icon from './Icon'

interface Props {
    value: string | undefined
    onChange: (v: string | undefined) => void
    label?: string
    error?: string
}

const DateInput = ({ value, onChange, label, error }: Props) => {
    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            <InputContainer
                onChange={(v) => v && onChange(new Date(v).toISOString())}
                value={value}
                clearIcon=""
                calendarIcon={<Icon name="calendar" />}
                locale="fr-FR"
            />
            <FormError error={error} />
        </ComponentContainer>
    )
}

export default DateInput

const ComponentContainer = styled.div`
    margin: 5px 0;
    display: flex;
    flex-direction: column;
`
const InputContainer = styled(DateTimePicker)`
    .react-datetime-picker__wrapper {
        border: none;
    }
    border: none;
    outline: none;
    padding: 0 20px;
    background-color: #eee;
    ${INNER_SHADOW}

    border-radius: ${CMPNT_HEIGHT * 0.25}px;
    width: -webkit-fill-available;
    height: ${CMPNT_HEIGHT}px;
    cursor: pointer;

    color: #000;
    font-size: 16px;
    font-family: Nunito;
`
const TextAreaContainer = styled.textarea`
    border: none;
    outline: none;
    padding: 20px;
    background-color: #eee;
    ${INNER_SHADOW}

    border-radius: ${CMPNT_HEIGHT * 0.25}px;
    width: -webkit-fill-available;
    height: ${CMPNT_HEIGHT}px;
    cursor: pointer;

    color: #000;
    font-size: 16px;
    font-family: Nunito;
`
