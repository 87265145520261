import * as React from 'react'

import { styled } from 'styled-components'

import Icon from '../components/Icon'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import {
    CITIES_RESPONSIVE,
    MAIN_COLOR,
    OUTER_SHADOW,
    SECONDARY_COLOR,
    STEPS_RESPONSIVE,
    TEMPORARY_CITY,
} from '../utils/constant'
import useApi from '../utils/webservices/useApi'
import Loading from '../components/Loading'
import { useNavigate } from 'react-router-dom'
import { isAfter } from 'date-fns'
import NoItem from '../components/NoItem'
import Podium from '../components/Podium'

interface Props {}

const Home = ({}: Props) => {
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [cities, setCities] = React.useState<City[]>([])
    const [users, setUsers] = React.useState<JourneyUser[]>([])
    const [stepsRanking, setStepsRanking] = React.useState<StepRanking[]>([])
    const [globalRanking, setGlobalRanking] = React.useState<GlobalRanking[]>([])

    React.useEffect(() => {
        Promise.all([
            webservices.journeys.getAvailableCities(),
            webservices.journeys.getAvailableSteps(),
            webservices.journeys.getRanking(),
            webservices.journeys.getUsers(),
        ])
            .then(([c, s, r, u]) => {
                const hiddenCities: City[] = new Array(c.total - c.cities.length).fill(
                    TEMPORARY_CITY
                )
                setCities([
                    ...c.cities.sort((a, b) => (isAfter(a.startDate, b.startDate) ? 1 : -1)),
                    ...hiddenCities,
                ])
                setSteps(s.reverse())
                setStepsRanking(r)
                setUsers(u)
            })
            .catch(() => {
                setStatus('error')
            })
            .finally(() => {
                setStatus('fetched')
            })
    }, [])

    React.useEffect(() => {
        const allPoints = stepsRanking.map((sr) => sr.rankings).flat()
        const globalR: GlobalRanking[] = users.map((u) => ({
            user: u,
            time: allPoints
                .filter((r) => r.user.id === u.id)
                .reduce((acc, cur) => cur.time + acc, 0),
        }))
        setGlobalRanking(globalR.sort((a, b) => a.time - b.time))
    }, [users, stepsRanking])

    return (
        <Container>
            <Title>C'est parti,</Title>
            <Title>pour la plus grande compétition sportive de l'année !</Title>
            <SectionContainer>
                <SectionTitle>Et c'est parti pour ...</SectionTitle>
                {status === 'loading' ? (
                    <Loading type="transparent" />
                ) : steps.length === 0 ? (
                    <NoItem />
                ) : (
                    <>
                        <Carousel responsive={STEPS_RESPONSIVE}>
                            {steps.map((step) => {
                                const picture = cities.find((c) => c.id === step.endCityId)?.picture
                                return (
                                    <StepContainer
                                        key={step.id}
                                        onClick={() => navigate(`/steps/${step.id}`)}>
                                        <StepPictureContainer>
                                            <StepPicture src={picture} />
                                        </StepPictureContainer>
                                        <StepBanner>
                                            <StepTitle>{step.title}</StepTitle>
                                            <Icon name="forward" />
                                        </StepBanner>
                                    </StepContainer>
                                )
                            })}
                        </Carousel>
                        <PageLink onClick={() => navigate('/steps')}>
                            Voir toutes les étapes →
                        </PageLink>
                    </>
                )}
            </SectionContainer>
            <SectionContainer>
                <SectionTitle>Les étapes du périple</SectionTitle>
                {status === 'loading' ? (
                    <Loading type="transparent" />
                ) : cities.length === 0 ? (
                    <NoItem />
                ) : (
                    <Carousel responsive={CITIES_RESPONSIVE}>
                        {cities.map((city) => (
                            <CityContainer
                                key={city.id}
                                onClick={() =>
                                    city.id !== TEMPORARY_CITY.id && navigate(`/cities/${city.id}`)
                                }>
                                <CityPicture src={city.picture} />
                            </CityContainer>
                        ))}
                    </Carousel>
                )}
            </SectionContainer>
            <SectionContainer>
                <SectionTitle>Classement actuel</SectionTitle>
                {status === 'loading' ? (
                    <Loading type="transparent" />
                ) : globalRanking.length < 3 ? (
                    <div>Classement non disponible pour le moment</div>
                ) : (
                    <>
                        <Podium globalRanking={globalRanking} />
                        <PageLink onClick={() => navigate('/ranking')}>
                            Voir le détail du classement →
                        </PageLink>
                    </>
                )}
            </SectionContainer>
        </Container>
    )
}

export default Home

const Container = styled.div``
const Title = styled.h1`
    margin: 0;
    font-size: 23px;
    font-weight: 900;
`
const SectionContainer = styled.div`
    margin: 30px 0;
`
const SectionTitle = styled.h2`
    margin: 0;
    font-size: 20px;
    font-weight: 800;
`
const SectionItemContainer = styled.div`
    background-color: #fff;
    cursor: pointer;
    ${OUTER_SHADOW}
    margin: 10px;
    overflow: hidden;

    &:hover {
        & > img {
            -webkit-transform: scale(1.1);
            transform: scale(1.2);
        }
        & > div {
            & > img {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }
        }

        background-color: ${MAIN_COLOR};
        color: #fff;
        & > div {
            & > svg {
                stroke: #fff;
            }
        }
    }
`
const STEP_PICTURE_RADIUS = 20
const CITY_PICTURE_RADIUS = 150
const StepContainer = styled(SectionItemContainer)`
    border-radius: ${STEP_PICTURE_RADIUS}px;
`
const CityContainer = styled(SectionItemContainer)`
    border-radius: ${CITY_PICTURE_RADIUS}px;
    height: ${CITY_PICTURE_RADIUS}px;
    width: ${CITY_PICTURE_RADIUS}px;
`

const StepPictureContainer = styled.div`
    overflow: hidden;
    height: 250px;
`
const ItemPicture = styled.img`
    width: 100%;
    object-fit: cover;

    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.1s ease-in-out;
`
const StepPicture = styled(ItemPicture)`
    height: 250px;
    border-radius: ${STEP_PICTURE_RADIUS}px ${STEP_PICTURE_RADIUS}px 0 0;
`
const CityPicture = styled(ItemPicture)`
    height: ${CITY_PICTURE_RADIUS}px;
    width: ${CITY_PICTURE_RADIUS}px;
`
const Banner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 5px 20px;
    cursor: pointer;
`
const StepBanner = styled(Banner)`
    border-radius: 0 0 ${STEP_PICTURE_RADIUS}px ${STEP_PICTURE_RADIUS}px;
`
const StepTitle = styled.h3`
    text-transform: uppercase;
    font-size: 18px;
`
const PageLink = styled.span`
    flex: 1;
    color: #444;
    color: ${SECONDARY_COLOR};
    display: flex;
    cursor: pointer;
    font-weight: 900;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;

    &:hover {
        text-decoration: underline;
    }
`
