import { Store } from 'effector'
import { persist } from 'effector-storage/local'

import * as AccounStore from './account'
import * as JourneyStore from './journey'

export const stores: Store<any>[] = [AccounStore.store, JourneyStore.store]

export const logout = () => {
    AccounStore.actions.logout()
    JourneyStore.actions.logout()
}

stores.map((s) => persist({ store: s }))
