import * as React from 'react'

import { styled } from 'styled-components'

import { CMPNT_HEIGHT, INNER_SHADOW } from '../utils/constant'
import FormLabel from './FormLabel'
import FormError from './FormError'

interface Props {
    name: string
    value: string | undefined
    onChange: React.ChangeEventHandler<any>
    placeholder: string
    label?: string
    type?: React.HTMLInputTypeAttribute | 'long-text'
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    error?: string
}

const Input = ({
    name,
    value,
    placeholder,
    onChange,
    label,
    type = 'text',
    onBlur,
    error,
}: Props) => {
    if (type === 'long-text') {
        return (
            <ComponentContainer>
                {label && <FormLabel label={label} />}
                <TextAreaContainer
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                />
                <FormError error={error} />
            </ComponentContainer>
        )
    }

    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            <InputContainer
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                placeholder={placeholder}
            />
            <FormError error={error} />
        </ComponentContainer>
    )
}

export default Input

const ComponentContainer = styled.div`
    margin: 5px 0;
    display: flex;
    flex-direction: column;
`
const InputContainer = styled.input<{ type: React.HTMLInputTypeAttribute }>`
    border: none;
    outline: none;
    padding: 0 20px;
    background-color: #eee;
    ${INNER_SHADOW}

    border-radius: ${CMPNT_HEIGHT * 0.25}px;
    width: -webkit-fill-available;
    height: ${CMPNT_HEIGHT}px;
    cursor: pointer;

    color: #000;
    font-size: 16px;
    font-family: Nunito;
    ${(props) => props.type === 'date' && 'text-transform: uppercase;'}
`
const TextAreaContainer = styled.textarea`
    border: none;
    outline: none;
    padding: 20px;
    background-color: #eee;
    ${INNER_SHADOW}

    border-radius: ${CMPNT_HEIGHT * 0.25}px;
    width: -webkit-fill-available;
    height: ${CMPNT_HEIGHT}px;
    cursor: pointer;

    color: #000;
    font-size: 16px;
    font-family: Nunito;
`
