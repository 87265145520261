import * as React from 'react'

import webservices from './webservices'
import useReducer from '../store/useReducer'
import * as AccountStore from '../store/account'
import * as JourneyStore from '../store/journey'
import Axios, { AxiosResponse } from 'axios'

const base_url = 'https://toxic-mercie-barystop-1104c7c1.koyeb.app'

const useApi = () => {
    const { token, refresh } = useReducer(AccountStore.store, (s) => s)
    const { selected } = useReducer(JourneyStore.store, (s) => s)

    const axiosApiInstance = Axios.create()
    const [ws, setWs] = React.useState<WebServicesData>(webservices(axiosApiInstance, selected?.id))

    const refreshAccessToken = () =>
        axiosApiInstance
            .post(base_url + '/login/refresh', { refreshToken: refresh })
            .then((res: AxiosResponse) => res.data)
            .then((data: TokenResponse) => {
                AccountStore.actions.setToken(data)
                return data.token
            })

    // Request interceptor for API calls
    axiosApiInstance.interceptors.request.use(
        async (config: any) => {
            config.headers = token
                ? {
                      Authorization: `Bearer ${token.token}`,
                      Accept: 'application/json',
                  }
                : {
                      Accept: 'application/json',
                  }
            return config
        },
        (error: any) => {
            Promise.reject(error)
        }
    )

    axiosApiInstance.interceptors.response.use(
        (response: any) => {
            return response
        },
        async function (error: any) {
            const originalRequest = error.config
            if (error.response.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true
                const access_token = await refreshAccessToken()
                axiosApiInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
                return axiosApiInstance(originalRequest)
            }
            return Promise.reject(error)
        }
    )

    React.useEffect(() => {
        setWs(webservices(axiosApiInstance, selected?.id))
    }, [token, selected])

    return ws
}

export default useApi
