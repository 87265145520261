import * as React from 'react'

import styled from 'styled-components'
import Lottie from 'react-lottie'

const NoItem = () => {
    return (
        <Container>
            <Lottie
                options={{
                    loop: true,
                    autoplay: true,
                    animationData: require('../assets/lottie/empty.json'),
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice',
                    },
                }}
                height={300}
                width={300}
            />
        </Container>
    )
}

export default NoItem

const Container = styled.div`
    height: 100%;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`
