import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import NoItem from '../../NoItem'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'
import useReducer from '../../../utils/store/useReducer'
import { useNavigate, useParams } from 'react-router-dom'
import * as JourneyStore from '../../../utils/store/journey'

interface Props {}

const contentSchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
})

const AddEditJourneyContent = ({}: Props) => {
    const Alert = useAlert()
    const params = useParams()
    const webservices = useApi()
    const navigate = useNavigate()

    const { selected } = useReducer(JourneyStore.store, (s) => s)

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [content, setContent] = React.useState<ContentObject | undefined>()
    const [deleting, setDeleting] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (params.contentId) {
            webservices.contents
                .get(params.contentId)
                .then(setContent)
                .then(() => setStatus('fetched'))
                .catch(() => setStatus('error'))
        } else {
            setStatus('fetched')
        }
    }, [])

    const deleteContent = () => {
        if (content) {
            setDeleting(true)
            webservices.contents
                .delete(content.id)
                .then(() => {
                    Alert.success('Le contenu a bien été supprimé !')
                    navigate('/admin/journeyContents')
                })
                .catch(() => Alert.error('Un problème est survenu..'))
                .finally(() => setDeleting(false))
        }
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!content && status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    content || {
                        title: '',
                        description: '',
                        picture: '',
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    content
                        ? webservices.contents
                              .update(content.id, {
                                  ...values,
                                  journeyId: selected?.id || '',
                              })
                              .then(() => {
                                  Alert.success('Le contenu a bien été modifié !')
                                  navigate('/admin/journeyContents')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                        : webservices.journeys
                              .createContent({
                                  ...values,
                                  journeyId: selected?.id || '',
                              })
                              .then(() => {
                                  Alert.success('Le contenu a bien été créé !')
                                  navigate('/admin/journeyContents')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                }}
                validationSchema={contentSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Titre du contenu"
                            name="title"
                            type="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Titre du contenu"
                            error={errors.title}
                        />
                        <Input
                            label="Description du contenu"
                            name="description"
                            type="long-text"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Description du contenu"
                            error={errors.description}
                        />
                        <PictureInput
                            label="Photo du contenu"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                            error={errors.picture}
                        />
                        <Buttons>
                            {content && (
                                <Button
                                    onclick={deleteContent}
                                    status={deleting ? 'loading' : 'active'}
                                    label="Supprimer le contenu"
                                    type="secondary"
                                />
                            )}
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={content ? 'Modifier le contenu' : 'Créer le contenu'}
                                type="primary"
                                position={!content ? 'RIGHT' : undefined}
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddEditJourneyContent

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
