import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { useNavigate, useParams } from 'react-router-dom'
import NoItem from '../../NoItem'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'
import CityMap from '../../CityMap'
import DateInput from '../../DateInput'

interface Props {}

const citySchema = object({
    name: string().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
    jsonFile: string().required('Champ obligatoire'),
    startDate: string().required('Champ obligatoire'),
})

const AddEditCity = ({}: Props) => {
    const Alert = useAlert()
    const params = useParams()
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>(params.cityId ? 'loading' : 'fetched')

    const [city, setCity] = React.useState<City | undefined>()
    const [deleting, setDeleting] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (params.cityId) {
            webservices.cities
                .get(params.cityId)
                .then(setCity)
                .then(() => setStatus('fetched'))
                .catch(() => setStatus('error'))
        } else {
            setStatus('fetched')
        }
    }, [])

    const deleteCity = () => {
        if (city) {
            setDeleting(true)
            webservices.cities
                .delete(city.id)
                .then(() => {
                    Alert.success('La ville a bien été supprimée !')
                    navigate('/admin/cities')
                })
                .catch(() => Alert.error('Un problème est survenu..'))
                .finally(() => setDeleting(false))
        }
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!city && status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    city || {
                        name: '',
                        picture: '',
                        jsonFile: '',
                        startDate: '',
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    city
                        ? webservices.cities
                              .update(city.id, {
                                  ...values,
                                  name:
                                      values.name.charAt(0).toUpperCase() +
                                      values.name.slice(1).toLowerCase(),
                              })
                              .then(() => {
                                  Alert.success('La ville a bien été modifiée !')
                                  navigate('/admin/cities')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                        : webservices.journeys
                              .createCity({
                                  ...values,
                                  name:
                                      values.name.charAt(0).toUpperCase() +
                                      values.name.slice(1).toLowerCase(),
                              })
                              .then(() => {
                                  Alert.success('La ville a bien été créée !')
                                  navigate('/admin/cities')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                }}
                validationSchema={citySchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Nom de la ville"
                            name="name"
                            type="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Nom de la ville"
                            error={errors.name}
                        />
                        {values.name && (
                            <Link
                                href={`https://nominatim.openstreetmap.org/search.php?q=France+${encodeURI(values.name)}&polygon_geojson=1&format=json`}
                                target="_blank">
                                Pour récupérer le GeoJson du tour de la ville. Si pas France alors
                                il vous faudra changer le lien. →
                            </Link>
                        )}
                        <Input
                            label="Limites de la ville"
                            name="jsonFile"
                            type="long-text"
                            value={values.jsonFile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Limites de la ville (GeoJson)"
                            error={errors.jsonFile}
                        />
                        {values.jsonFile && <CityMap jsonFile={values.jsonFile} />}
                        <DateInput
                            label="Déblocage de la ville"
                            value={values.startDate}
                            onChange={(v) => setFieldValue('startDate', v)}
                            error={errors.startDate}
                        />
                        <PictureInput
                            label="Photo"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                            error={errors.picture}
                        />
                        <Buttons>
                            {city && (
                                <Button
                                    onclick={deleteCity}
                                    status={deleting ? 'loading' : 'active'}
                                    label="Supprimer la ville"
                                    type="secondary"
                                />
                            )}
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={city ? 'Modifier la ville' : 'Créer la ville'}
                                type="primary"
                                position={!city ? 'RIGHT' : undefined}
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddEditCity

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
const Link = styled.a`
    display: flex;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    ${!isMobile && 'padding-left: 50%;'}
`
