import * as React from 'react'

import styled from 'styled-components'
import { INNER_SHADOW, OUTER_SHADOW } from '../utils/constant'

import { motion } from 'framer-motion'

import FormLabel from './FormLabel'
import FormError from './FormError'

interface Props {
    value: boolean
    setValue: (v: boolean) => void
    size: number
    label?: string
    error?: string
}

const Switch = ({ value, setValue, size, label, error }: Props) => {
    const toggleSwitch = () => setValue(!value)

    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            <SwitchContainer
                className="switch"
                data-isOn={value}
                onClick={toggleSwitch}
                size={size}>
                <motion.div
                    className="handle"
                    layout
                    transition={{
                        type: 'spring',
                        stiffness: 700,
                        damping: 30,
                    }}
                />
            </SwitchContainer>
            <FormError error={error} align="left" />
        </ComponentContainer>
    )
}

export default Switch

const ComponentContainer = styled.div`
    flex-direction: column;
    display: flex;
    margin: 10px 0;
`
const SwitchContainer = styled.div<{ size: number }>`
    width: ${(props) => props.size * 2}px;
    background-color: #eeeeee;
    ${INNER_SHADOW}
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: ${(props) => props.size * 0.1}px;
    cursor: pointer;

    &[data-isOn='true'] {
        justify-content: flex-end;
        background-color: #49ad8f;
    }

    .handle {
        width: ${(props) => props.size}px;
        height: ${(props) => props.size}px;
        background-color: #fff;
        border-radius: 40px;
        ${OUTER_SHADOW}
    }
`
