import * as React from 'react'

import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW, PRIMARY_COLOR, SECONDARY_COLOR } from '../../../utils/constant'

import Icon from '../../Icon'
import NoItem from '../../NoItem'
import Loading from '../../Loading'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

const ListResponses = ({}: Props) => {
    const params = useParams()
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [step, setStep] = React.useState<Step | undefined>()
    const [users, setUsers] = React.useState<JourneyUser[]>([])
    const [challenge, setChallenge] = React.useState<Challenge | undefined>()
    const [responses, setResponses] = React.useState<ChallengeResponse[]>([])

    const stepId = params.stepId
    const challengeId = params.challengeId

    if (!stepId || !challengeId) {
        return <NoItem />
    }

    React.useEffect(() => {
        Promise.all([
            webservices.steps.get(stepId),
            webservices.challenges.get(challengeId),
            webservices.challenges.getAllResponses(challengeId),
            webservices.journeys.getUsers(),
        ])
            .then(([st, ch, res, us]) => {
                setStep(st)
                setChallenge(ch)
                setResponses(res)
                setUsers(us)
                setStatus('fetched')
            })
            .catch(() => setStatus('error'))
    }, [])

    const renderItem = (item: ChallengeResponse) => {
        const user = users.find((u) => u.id === item.userId)

        if (!user) {
            return null
        }

        return (
            <Line key={item.id}>
                <Picture src={user.picture} size={PICTURE_SIZE} />
                <Column>
                    <Name>
                        {user.firstName} {user.lastName}
                    </Name>
                    <Content>{item.content}</Content>
                    {item.picture && (
                        <Content>
                            <Picture src={item.picture} size={PICTURE_SIZE * 0.5} />
                            {/* TODO: display  bigger picture on hover or modal ?   */}
                        </Content>
                    )}
                </Column>
            </Line>
        )
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (status === 'error' || !step || !challenge || responses.length === 0) {
        return <NoItem />
    }

    return (
        <>
            <StepName>{step.title}</StepName>
            <ChallengeName>{challenge.title}</ChallengeName>
            {responses.map(renderItem)}
        </>
    )
}

export default ListResponses

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`

const Picture = styled.img<{ size: number }>`
    ${({ size }) => `
        width: ${size}px;
        height: ${size}px;
        border-radius: ${size}px;
        object-fit: cover;
        ${OUTER_SHADOW}
        margin: 0 ${0.25 * size}px;
    `}
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Action = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Content = styled.div`
    color: #777;
`
const StepName = styled.div`
    color: #fff;
    font-size: 30px;
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: ${SECONDARY_COLOR};
`
const ChallengeName = styled.div`
    color: #fff;
    font-size: 30px;
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: ${PRIMARY_COLOR};
`
