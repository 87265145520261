import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { GoogleOAuthProvider } from '@react-oauth/google'

import AlertTemplate from 'react-alert-template-basic'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const options = {
    timeout: 5000,
    transition: transitions.SCALE,
    position: positions.TOP_RIGHT,
}

root.render(
    <React.StrictMode>
        <AlertProvider template={AlertTemplate} {...options}>
            <GoogleOAuthProvider clientId="721849440329-mkkmal88m6i801aeda260ok85q7rheor.apps.googleusercontent.com">
                <App />
            </GoogleOAuthProvider>
        </AlertProvider>
    </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
