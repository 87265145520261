import * as React from 'react'
import styled from 'styled-components'

import { LatLng, Map } from 'leaflet'
import { MapContainer, Polygon, TileLayer, CircleMarker } from 'react-leaflet'

import { MAIN_COLOR, OUTER_SHADOW, SECONDARY_COLOR, TERCARY_COLOR } from '../utils/constant'

import Icon from './Icon'
import { RotatingLines } from 'react-loader-spinner'
import ErrorLogger from './ErrorLogger'

interface Props {
    jsonFile: string
    height?: number
    userLocation?: LatLng
    setUserLocation?: React.Dispatch<React.SetStateAction<LatLng | undefined>>
}

const DEFAULT_ZOOM = 11

export const reverseIfArrayOfSmthg = (arr: any): any => {
    if (Array.isArray(arr)) {
        if (arr.length === 0) {
            return arr
        } else {
            if (Array.isArray(arr[0])) {
                return arr.map((a) => reverseIfArrayOfSmthg(a))
            } else {
                return arr.reverse()
            }
        }
    }
    return arr
}

const CityMap = ({ jsonFile, height, userLocation, setUserLocation }: Props) => {
    const [map, setMap] = React.useState<Map | null>(null)
    const [locating, setLocating] = React.useState<boolean>(false)

    React.useEffect(() => {
        locateUser()
    }, [])

    const locateUser = () => {
        if (setUserLocation) {
            setLocating(true)
            navigator.geolocation.getCurrentPosition(
                (data) => {
                    const position = new LatLng(data.coords.latitude, data.coords.longitude)
                    setUserLocation(position)
                    if (map) {
                        // @ts-ignore
                        map.setView(position)
                    }
                    setLocating(false)
                },
                (error) => {
                    ErrorLogger(error)
                    setLocating(false)
                }
            )
        }
    }

    const jsonContent = JSON.parse(jsonFile)
    const cityLocation = {
        lat: jsonContent.lat,
        lng: jsonContent.lon,
    }

    const centerOnCity = () => {
        if (map) {
            // @ts-ignore
            map.setView(cityLocation)
        }
    }

    // @ts-ignore
    const purpleOptions = { color: SECONDARY_COLOR }
    const cityPolygon = reverseIfArrayOfSmthg(jsonContent.geojson.coordinates)

    return (
        <Container height={height}>
            <MapContainer
                ref={setMap}
                //@ts-ignore
                center={userLocation || cityLocation}
                zoom={DEFAULT_ZOOM}
                style={{
                    height: height || '-webkit-fill-available',
                    width: '100%',
                }}
                scrollWheelZoom={false}>
                <TileLayer
                    //@ts-ignore
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Polygon positions={cityPolygon} pathOptions={purpleOptions} />
                {userLocation && (
                    <CircleMarker
                        center={userLocation}
                        //@ts-ignore
                        radius={5}
                    />
                )}
            </MapContainer>
            <MapActionContainer leftAligned={!setUserLocation}>
                <LocationAction onClick={centerOnCity}>
                    <Icon name="city" color={MAIN_COLOR} />
                </LocationAction>
                {setUserLocation && (
                    <LocationAction onClick={locateUser}>
                        {!locating ? (
                            <Icon name="location" color={MAIN_COLOR} />
                        ) : (
                            <RotatingLines
                                visible={true}
                                strokeColor={TERCARY_COLOR}
                                // @ts-ignore
                                width={30}
                            />
                        )}
                    </LocationAction>
                )}
            </MapActionContainer>
        </Container>
    )
}

export default CityMap

const MIN_HEIGHT = 300
const Container = styled.div<{ height?: number }>`
    height: ${(props) => props.height || MIN_HEIGHT}px;
`
const ACTION_BUTTON_SIZE = 50

const LocationAction = styled.div`
    width: ${ACTION_BUTTON_SIZE}px;
    height: ${ACTION_BUTTON_SIZE}px;
    border-radius: 5px;

    background-color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
    ${OUTER_SHADOW}

    cursor: pointer;
    &:hover {
        background-color: ${SECONDARY_COLOR};
        & > svg {
            fill: #fff;
        }
    }
`
const MapActionContainer = styled.div<{ leftAligned: boolean }>`
    justify-content: ${(props) => (props.leftAligned ? 'flex-start' : 'space-around')};
    width: ${ACTION_BUTTON_SIZE * 2.5}px;
    top: -${ACTION_BUTTON_SIZE * 1.25}px;
    align-items: center;
    position: relative;
    z-index: 1000;
    display: flex;
`
