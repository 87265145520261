import * as React from 'react'

import { styled } from 'styled-components'

import { useGoogleLogin } from '@react-oauth/google'

import Button from '../components/Button'

import * as AccountStore from '../utils/store/account'
import useApi from '../utils/webservices/useApi'
import { SECONDARY_COLOR } from '../utils/constant'
import ErrorLogger from '../components/ErrorLogger'

interface Props {}

const Login = ({}: Props) => {
    const webservices = useApi()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const [height, setHeight] = React.useState<number>(window.innerHeight)

    const windowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize)
        }
    }, [])

    const login = useGoogleLogin({
        onSuccess: (response) => {
            webservices
                .login(response.code)
                .then((tokens) => {
                    AccountStore.actions.setRefresh(tokens.refreshToken)
                    AccountStore.actions.setToken({
                        expiresIn: tokens.expiresIn,
                        email: tokens.email,
                        token: tokens.token,
                    })
                })
                .catch(ErrorLogger)
                .finally(() => setLoading(false))
        },
        onError: (error) => {
            setLoading(false)
            ErrorLogger(error)
        },
        onNonOAuthError: () => {
            setLoading(false)
        },
        flow: 'auth-code',
    })

    return (
        <Container width={width} height={height}>
            <ImageBackground src="./login_background.jpeg" width={width} height={height} />
            <LoginPanel>
                <LoginTitle>Baryst'App ®</LoginTitle>
                <LoginText>
                    Bienvenue sur votre toute nouvelle application Barystop ®. Une fois connecté,
                    vous pourrez suivre l'ensemble des participants, étapes et classement tout au
                    long de la course.
                </LoginText>
                <Button
                    type="primary"
                    label="Connexion"
                    position="RIGHT"
                    onclick={() => {
                        setLoading(true)
                        login()
                    }}
                    status={loading ? 'loading' : 'active'}
                />
            </LoginPanel>
        </Container>
    )
}

export default Login

const Container = styled.div<{ height: number; width: number }>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`
const ImageBackground = styled.img<{ height: number; width: number }>`
    object-fit: cover;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
`
const LoginPanel = styled.div`
    background-color: #fffd;
    box-shadow: 0px 0px 5px #0009;

    z-index: 1;
    margin: 30px;
    padding: 20px;
    max-width: 300px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
`
const LoginTitle = styled.h1`
    margin: 0;
    margin-right: auto;
    margin-bottom: 10px;

    text-decoration: underline;
    background-color: ${SECONDARY_COLOR};
    padding: 0 20px;
    color: #fff;
`
const LoginText = styled.p`
    margin: 0;
`
const TempLogin = styled.h4`
    margin: 0;
    color: blue;
    font-size: 12px;
    text-align: right;
    text-transform: uppercase;
    text-decoration: underline;
`
