import * as React from 'react'

import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import Loading from '../../Loading'
import NoItem from '../../NoItem'
import Icon from '../../Icon'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

const ListUsers = ({}: Props) => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [users, setUsers] = React.useState<JourneyUser[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        webservices.journeys
            .getUsers()
            .then((u) => setUsers(u.sort((a, b) => a.firstName.localeCompare(b.firstName))))
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    const renderItem = (item: JourneyUser) => (
        <Line key={item.id} onClick={() => navigate('/admin/users/' + item.id)}>
            <Picture src={item.picture} male={item.sex === 'MALE'} />
            {isMobile ? (
                <Column>
                    <Name>{item.firstName + ' ' + item.lastName}</Name>
                    <Pseudo>{item.pseudo}</Pseudo>
                    <Admin isadmin={item.admin}>
                        {item.admin ? 'Administrateur' : 'Utilisateur'}
                    </Admin>
                    <Email>{item.email}</Email>
                </Column>
            ) : (
                <>
                    <Column>
                        <Name>{item.firstName + ' ' + item.lastName}</Name>
                        <Pseudo>{item.pseudo}</Pseudo>
                    </Column>
                    <Column>
                        <Admin isadmin={item.admin}>
                            {item.admin ? 'Administrateur' : 'Utilisateur'}
                        </Admin>
                        <Email>{item.email}</Email>
                    </Column>
                </>
            )}
            {!isMobile && (
                <Action>
                    <Icon name="forward" color="#FFF" />
                </Action>
            )}
        </Line>
    )

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (users.length === 0 || status === 'error') {
        return <NoItem />
    }

    return users.length === 0 ? <NoItem /> : users.map(renderItem)
}

export default ListUsers

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`

const Picture = styled.img<{ male: boolean }>`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
    border: 3px solid ${(props) => (props.male ? 'lightblue' : 'pink')};
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Pseudo = styled.div`
    font-size: ${isMobile ? 18 : 20}px;
    font-weight: 300;
    color: #999;
`
const Name = styled.div`
    font-size: ${isMobile ? 20 : 25}px;
    font-weight: 700;
    color: #000;
`
const Admin = styled.div<{ isadmin: boolean }>`
    font-size: ${isMobile ? 14 : 16}px;
    font-weight: ${(props) => (props.isadmin ? 900 : 300)};
    color: #000;
`
const Email = styled.div`
    font-size: ${isMobile ? 14 : 16}px;
    font-weight: 300;
    color: #999;
`
const Action = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
