import * as React from 'react'

import LayoutMobile from './Layout.mobile'
import LayoutWeb from './Layout.web'

import { isMobile } from 'react-device-detect'

interface Props extends React.PropsWithChildren {
    items: ScreenCog[]
    current?: ScreenCog
}

const ApplicationLayout = ({ children, items, current }: Props) => {
    return isMobile ? (
        <LayoutMobile items={items} current={current}>
            {children}
        </LayoutMobile>
    ) : (
        <LayoutWeb items={items} current={current}>
            {children}
        </LayoutWeb>
    )
}

export default ApplicationLayout
