import * as React from 'react'

import { useNavigate } from 'react-router-dom'
import { AdminScreens } from '../utils/screens/screens'

import { styled } from 'styled-components'
import { MAIN_COLOR, OUTER_SHADOW } from '../utils/constant'

import Icon from '../components/Icon'

interface Props {}

const Admin = ({}: Props) => {
    const navigate = useNavigate()

    return (
        <Container>
            {AdminScreens.filter((itm) => !!itm.adminIcon).map((itm, idx) => (
                <AdminCard index={idx} onClick={() => navigate(itm.path)}>
                    <LeftContainer>
                        <IconContainer>
                            <Icon name={itm.adminIcon} size={25} color={MAIN_COLOR} />
                        </IconContainer>
                        <AdminLabel>{itm.title}</AdminLabel>
                    </LeftContainer>
                    <Icon name="forward" size={30} color="#fff" />
                </AdminCard>
            ))}
        </Container>
    )
}

export default Admin

const Container = styled.div``
const CARD_SIZE = 75
const CARD_RADIUS = 10
const CARD_MARGIN = 10
const PASTEL_COLOR = [
    '#ADD8E6',
    '#77DD77',
    '#B19CD9',
    '#FFFACD',
    '#FFDAB9',
    '#BDFCC9',
    '#E6E6FA',
    '#ADD8E6',
    '#77DD77',
    '#B19CD9',
    '#FFFACD',
    '#FFDAB9',
    '#BDFCC9',
    '#E6E6FA',
]
const AdminCard = styled.div<{ index: number }>`
    flex: 1;
    display: flex;

    height: ${CARD_SIZE}px;
    border-radius: ${CARD_RADIUS}px;
    padding: 0 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: ${CARD_MARGIN}px;

    background-color: ${(props) => PASTEL_COLOR[props.index]};

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    ${OUTER_SHADOW}

    cursor: pointer;
`
const IconContainer = styled.div`
    background-color: #fff;
    height: 50px;
    width: 50px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    ${OUTER_SHADOW}
`
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const AdminLabel = styled.div`
    text-transform: uppercase;
    font-weight: 900;
    color: #000;
`
