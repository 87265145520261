import * as React from 'react'

import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import Loading from '../../Loading'
import useApi from '../../../utils/webservices/useApi'
import Button from '../../Button'
import { useAlert } from 'react-alert'

interface Props {}

interface StepTeam {
    step: Step
    teams: Team[]
}

const ListTeams = ({}: Props) => {
    const Alert = useAlert()
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [stepTeams, setStepTeams] = React.useState<StepTeam[]>([])

    React.useEffect(() => {
        webservices.journeys
            .getSteps()
            .then(setSteps)
            .catch(() => setStatus('error'))
    }, [])

    const getAllTeams = () => {
        setStatus('loading')
        Promise.all(
            steps.map((step) =>
                webservices.steps.getTeams(step.id).then((t) => ({ step, teams: t }) as StepTeam)
            )
        )
            .then(setStepTeams)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }

    React.useEffect(() => {
        getAllTeams()
    }, [steps])

    const renderItem = (item: Team) => (
        <Line key={item.id}>
            {item.users.map((u) => (
                <UserContainer key={u.id}>
                    <Picture src={u.picture} male={u.sex === 'MALE'} />
                    <Column>
                        <Name>
                            {u.firstName} {u.lastName}
                        </Name>
                        <Pseudo>{u.pseudo}</Pseudo>
                    </Column>
                </UserContainer>
            ))}
        </Line>
    )

    if (status !== 'fetched' || steps.length === 0 || stepTeams.length === 0) {
        return <Loading type="transparent" />
    }

    return (
        <Container>
            {stepTeams.map((st) => {
                return (
                    <StepContent>
                        <StepTitle>{st.step.title}</StepTitle>
                        {st.teams.length === 0 ? (
                            <NoContent>Aucun équipe disponible</NoContent>
                        ) : (
                            st.teams.map(renderItem)
                        )}
                        {/* <ButtonContainer>
                            <Button
                                position="RIGHT"
                                status="active"
                                type="primary"
                                label="Nouvelle équipe"
                                onclick={() => {
                                    webservices.steps
                                        .createTeam(st.step.id, { name: '' })
                                        .then((data) => {
                                            setSteps([...steps])
                                        })
                                        .catch(ErrorLogger)
                                }}
                            />
                        </ButtonContainer> */}
                    </StepContent>
                )
            })}
            <Warning>
                Attention, la regénaration des équipes va réinitialiser l'ensemble des réponses,
                arrivées et classement du voyage. Si vous êtes sur de vous alors double cliquez sur
                le bouton. Bisous.
            </Warning>
            <ButtonContainer>
                <Button
                    label="Génération des équipes"
                    type="primary"
                    status="active"
                    position="RIGHT"
                    double
                    onclick={() => {
                        webservices.journeys
                            .generateTeams()
                            .then(() => {
                                Alert.success('Les équipes ont bien été générées !')
                                getAllTeams()
                            })
                            .catch(() => Alert.error('Un problème est survenu..'))
                    }}
                />
            </ButtonContainer>
        </Container>
    )
}

export default ListTeams

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Container = styled.div`
    margin: 10px;
`
const StepContent = styled.div``
const StepTitle = styled.h2`
    font-size: 25px;
    font-weight: 700;
    color: #eee;

    margin: 0;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${MAIN_COLOR};
    ${OUTER_SHADOW}
`
const NoContent = styled.div`
    margin-bottom: 20px;
    padding: 20px 40px;
`
const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}
`

const Picture = styled.img<{ male: boolean }>`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;
    border: 3px solid ${(props) => (props.male ? 'lightblue' : 'pink')};

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Pseudo = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #333;
`
const Action = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const ButtonContainer = styled.div`
    display: flex;
`
const UserContainer = styled.div`
    display: flex;
    flex: 1;
`
const Warning = styled.p`
    color: #f00;
    text-align: right;
    margin: 30px 0;
`
