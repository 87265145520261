import * as React from 'react'

import { styled } from 'styled-components'

import { INNER_SHADOW, OUTER_SHADOW } from '../utils/constant'
import FormLabel from './FormLabel'
import FormError from './FormError'

interface Props {
    value: string | undefined
    setValue: (v: string) => void
    label?: string
    error?: string
}

const PictureInput = ({ value, setValue, label, error }: Props) => {
    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const uploadImage = async (event: any) => {
        const file = event.target.files[0]
        const base64 = await convertBase64(file)
        setValue(base64 as string)
    }

    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            <InputContainer>
                <input id="selectAvatar" type="file" onChange={uploadImage} />
                {value && <InputPreview src={value} />}
            </InputContainer>
            <FormError error={error} align="left" />
        </ComponentContainer>
    )
}

export default PictureInput

const ComponentContainer = styled.div`
    display: flex;
    margin: 5px 0;
    flex-direction: column;
`
const INPUT_SIZE = 125
const INPUT_RADIUS = 10

const InputContainer = styled.div`
    display: flex;
    align-items: center;

    input[type='file'] {
        border-radius: ${INPUT_RADIUS}px;
        height: ${INPUT_SIZE}px;
        width: ${INPUT_SIZE}px;

        border: none;
        outline: none;
        background-color: #eee;

        align-items: center;
        justify-content: center;
    }
    input[type='file']::file-selector-button {
        border: none;
        color: #000;
        ${INNER_SHADOW}

        cursor: pointer;
        height: 100%;
        width: 100%;
    }
`
const InputPreview = styled.img`
    border-radius: ${INPUT_RADIUS}px;
    height: ${INPUT_SIZE}px;
    width: ${INPUT_SIZE}px;

    object-fit: cover;
    margin-left: 10px;
    ${OUTER_SHADOW}
`
