import * as React from 'react'

import styled from 'styled-components'

import { INNER_SHADOW, MAIN_COLOR, OUTER_SHADOW } from '../constant'
import { useLocation, useNavigate } from 'react-router-dom'

import { RotatingLines } from 'react-loader-spinner'
import { HomeScreen } from '../screens/screens'
import Icon from '../../components/Icon'

import { logout } from '../store/store'
import useReducer from '../../utils/store/useReducer'
import * as AccountStore from '../../utils/store/account'

interface Props extends React.PropsWithChildren {
    items: ScreenCog[]
    current?: ScreenCog
}

const ApplicationLayout = ({ children, items, current }: Props) => {
    const { user } = useReducer(AccountStore.store, (s) => s)

    const [loading, setLoading] = React.useState<boolean>(false)
    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const [height, setHeight] = React.useState<number>(window.innerHeight)

    const windowResize = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    React.useEffect(() => {
        window.addEventListener('resize', windowResize)
        return () => {
            window.removeEventListener('resize', windowResize)
        }
    }, [])

    const navigate = useNavigate()
    const location = useLocation()
    const backpath = current?.backpath

    const HEADER_HEIGHT = location.pathname.startsWith('/admin') ? 100 : 0

    return (
        <Container height={height} width={width}>
            <Menu height={height}>
                <MenuItem
                    selected={location.pathname === HomeScreen.path}
                    onClick={() => navigate(HomeScreen.path)}>
                    <Icon
                        name="home"
                        color={location.pathname === HomeScreen.path ? MAIN_COLOR : '#fff'}
                    />
                </MenuItem>
                <MenuItems>
                    {items
                        .filter((i) => !!i.iconWeb)
                        .map((itm) => (
                            <MenuItem
                                key={itm.name}
                                onClick={() => navigate(itm.path)}
                                selected={location.pathname.startsWith(itm.path)}>
                                <Icon
                                    name={itm.iconWeb}
                                    color={
                                        location.pathname.startsWith(itm.path) ? MAIN_COLOR : '#fff'
                                    }
                                />
                            </MenuItem>
                        ))}
                </MenuItems>
                <MenuItem
                    onClick={() => navigate('/account')}
                    onDoubleClick={() => {
                        setLoading(true)
                        setTimeout(() => {
                            logout()
                            navigate('/')
                        }, 500)
                    }}>
                    {loading ? (
                        <RotatingLines width="30" strokeColor="#FFF" />
                    ) : (
                        <ConnectedPicture src={user?.picture} />
                    )}
                </MenuItem>
            </Menu>
            <Content>
                {location.pathname.startsWith('/admin') && (
                    <Header height={HEADER_HEIGHT}>
                        <TitleContainer>
                            {backpath && (
                                <BackIcon onClick={() => navigate(backpath)}>
                                    <Icon name="back" />
                                </BackIcon>
                            )}
                            <Title>{current?.title || ''}</Title>
                            {current?.action && (
                                <IconContainer
                                    onClick={() =>
                                        current.action?.destination &&
                                        navigate(current.action.destination)
                                    }>
                                    <Icon name={current.action.icon} color="#FFF" />
                                </IconContainer>
                            )}
                        </TitleContainer>
                    </Header>
                )}
                <ChildrenContainer height={height} headerHeight={HEADER_HEIGHT}>
                    {children}
                </ChildrenContainer>
            </Content>
        </Container>
    )
}

export default ApplicationLayout

const Container = styled.div<{ height: number; width: number }>`
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    background-color: ${MAIN_COLOR};
`
const CONTENT_RADIUS = 25
const MENU_WIDTH = 100
const MENU_PADDING = 20
const MENU_ITEM_RADIUS = 5

const Menu = styled.div<{ height: number }>`
    position: fixed;
    width: ${MENU_WIDTH}px;
    top: ${2 * MENU_PADDING}px;
    left: ${MENU_PADDING}px;
    bottom: ${2 * MENU_PADDING}px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: ${CONTENT_RADIUS}px;
`
const MenuItems = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
    align-items: center;
    margin: 100px 0;
`
const MenuItem = styled.div<{ selected?: boolean }>`
    cursor: pointer;

    width: ${MENU_WIDTH * 0.6}px;
    height: ${MENU_WIDTH * 0.6}px;

    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => props.selected && 'background-color: #fff;'}
    border-radius: ${MENU_ITEM_RADIUS}px;
`

const CONTENT_PADDING = 20
const Content = styled.div`
    position: fixed;
    top: ${MENU_PADDING}px;
    right: ${MENU_PADDING}px;
    bottom: ${MENU_PADDING}px;
    left: ${2 * MENU_PADDING + MENU_WIDTH}px;

    background-color: #f5f5f5;
    border-radius: ${CONTENT_RADIUS}px;

    ${INNER_SHADOW}
    padding: ${CONTENT_PADDING}px ${2 * CONTENT_PADDING}px;
`

const Header = styled.div<{ height: number }>`
    height: ${(props) => props.height}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const TitleContainer = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`
const Title = styled.div`
    display: flex;
    font-size: 40px;
    font-weight: 900;
`
const ICON_SIZE = 40
const IconContainer = styled.div`
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
    border-radius: ${ICON_SIZE}px;
    background-color: ${MAIN_COLOR};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
    cursor: pointer;
    ${OUTER_SHADOW}
`
const BackIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    cursor: pointer;
`
const PICTURE_SIZE = 55
const ConnectedContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
`
const ConnectedPicture = styled.img`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    // position: relative;
    // right: -${0.5 * PICTURE_SIZE}px;
`
const ConnectedName = styled.div`
    font-weight: 500;
    font-size: 18px;

    padding: 10px;
    padding-left: ${0.6 * PICTURE_SIZE}px;
    padding-right: ${0.25 * PICTURE_SIZE}px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
`
const ChildrenContainer = styled.div<{ height: number; headerHeight: number }>`
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    height: ${(props) =>
        props.height - props.headerHeight - 2 * (MENU_PADDING + CONTENT_PADDING)}px;
`
