import { createEvent, createStore } from 'effector'

export const actions = {
    setJourneys: createEvent<Journey[]>('setJourneys'),
    setSelected: createEvent<Journey>('setSelected'),
    logout: createEvent('logout'),
}

const initialState: JourneyState = {
    journeys: undefined,
    selected: undefined,
}

export const store = createStore<JourneyState>(initialState, {
    name: 'journeys',
})
    .on(actions.setJourneys, (s, journeys) => ({ ...s, journeys }))
    .on(actions.setSelected, (s, selected) => ({ ...s, selected }))
    .on(actions.logout, () => ({ ...initialState }))
