import * as React from 'react'

import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import Loading from '../../Loading'
import Icon from '../../Icon'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

interface StepContent {
    step: Step
    contents: ContentObject[]
}

const ListStepContents = ({}: Props) => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [stepContents, setStepContents] = React.useState<StepContent[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        webservices.journeys
            .getSteps()
            .then(setSteps)
            .catch(() => setStatus('error'))
    }, [])

    React.useEffect(() => {
        Promise.all(
            steps.map((step) =>
                webservices.steps
                    .getContents(step.id)
                    .then((c) => ({ step, contents: c }) as StepContent)
            )
        )
            .then(setStepContents)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [steps])

    const renderItem = (item: ContentObject) => (
        <Line key={item.id} onClick={() => navigate('/admin/stepContents/' + item.id)}>
            <Picture src={item.picture} />
            <Column>
                <Name>{item.title}</Name>
            </Column>
            {!isMobile && (
                <Action>
                    <Icon name="forward" color="#FFF" />
                </Action>
            )}
        </Line>
    )

    if (status !== 'fetched' || stepContents.length === 0 || steps.length === 0) {
        return <Loading type="transparent" />
    }

    return (
        <Container>
            {stepContents.map((sc) => {
                return (
                    <StepContent>
                        <StepTitle>{sc.step.title}</StepTitle>
                        {sc.contents.length === 0 ? (
                            <NoContent>Aucun contenu disponible</NoContent>
                        ) : (
                            sc.contents.map(renderItem)
                        )}
                    </StepContent>
                )
            })}
        </Container>
    )
}

export default ListStepContents

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Container = styled.div`
    margin: 10px;
`
const StepContent = styled.div``
const StepTitle = styled.h2`
    font-size: 25px;
    font-weight: 700;
    color: #eee;

    margin: 0;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${MAIN_COLOR};
    ${OUTER_SHADOW}
`
const NoContent = styled.div`
    margin-bottom: 20px;
    padding: 20px 40px;
`
const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`
const Picture = styled.img`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Action = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
