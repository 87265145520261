import * as React from 'react'

import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW, SECONDARY_COLOR } from '../../../utils/constant'

import Icon from '../../Icon'
import Loading from '../../Loading'
import useApi from '../../../utils/webservices/useApi'
import Button from '../../Button'
import { useAlert } from 'react-alert'
import { RotatingLines } from 'react-loader-spinner'

interface Props {}

interface StepBonus {
    step: Step
    bonus: Bonus[]
}

const ListBonus = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [deleting, setDeleting] = React.useState<string | undefined>()

    const [steps, setSteps] = React.useState<Step[]>([])
    const [users, setUsers] = React.useState<JourneyUser[]>([])
    const [stepBonus, setStepBonus] = React.useState<StepBonus[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        Promise.all([webservices.journeys.getSteps(), webservices.journeys.getUsers()])
            .then(([st, us]) => {
                setSteps(st)
                setUsers(us)
            })
            .catch(() => setStatus('error'))
    }, [])

    React.useEffect(() => {
        Promise.all(
            steps.map((step) =>
                webservices.steps.getBonus(step.id).then((b) => ({ step, bonus: b }))
            )
        )
            .then(setStepBonus)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [steps])

    const deleteBonus = (bonusId: string) => {
        setDeleting(bonusId)
        webservices.bonus
            .delete(bonusId)
            .then((data) => {
                Alert.success('Le bonus / malus a bien été supprimé !')
                setStepBonus(
                    stepBonus.map((sb) => ({
                        ...sb,
                        bonus: sb.bonus.filter((b) => b.id !== bonusId),
                    }))
                )
            })
            .catch(() => Alert.error('Un problème est survenu..'))
            .finally(() => setDeleting(undefined))
    }

    const renderItem = (item: Bonus) => {
        const user = users.find((u) => u.id === item.userId)

        if (!user) {
            return null
        }

        return (
            <Line key={item.id}>
                <Picture src={user?.picture} />
                <Column>
                    <Name>{user?.firstName}</Name>
                    <Points>{item.additionalTime}</Points>
                </Column>
                <Action onClick={() => deleteBonus(item.id)}>
                    {deleting === item.id ? (
                        <RotatingLines width="20" strokeColor="#FFF" />
                    ) : (
                        <Icon name="delete" color="#FFF" />
                    )}
                </Action>
            </Line>
        )
    }

    if (status !== 'fetched' || steps.length === 0 || stepBonus.length === 0) {
        return <Loading type="transparent" />
    }

    return (
        <Container>
            {stepBonus.map((sb) => {
                return (
                    <StepContent>
                        <StepTitle>{sb.step.title}</StepTitle>
                        {sb.bonus.length === 0 ? (
                            <NoContent>Aucun bonus disponible</NoContent>
                        ) : (
                            sb.bonus.map(renderItem)
                        )}
                        <ButtonContainer>
                            <Button
                                label="Nouveau bonus / malus"
                                type="primary"
                                status="active"
                                onclick={() => navigate(`/admin/bonus/${sb.step.id}/new`)}
                            />
                        </ButtonContainer>
                    </StepContent>
                )
            })}
        </Container>
    )
}

export default ListBonus

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Container = styled.div`
    margin: 10px;
`
const StepContent = styled.div``
const StepTitle = styled.h2`
    font-size: 25px;
    font-weight: 700;
    color: #eee;

    margin: 0;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${MAIN_COLOR};
    ${OUTER_SHADOW}
`
const NoContent = styled.div`
    margin-bottom: 20px;
    padding: 20px 40px;
`
const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}
`

const Picture = styled.img`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Points = styled.div`
    font-size: 20px;
    font-weight: 500;
    color: #333;
`
const ACTION_SIZE = 40
const Action = styled.div`
    background-color: blue;
    width: ${ACTION_SIZE}px;
    height: ${ACTION_SIZE}px;
    border-radius: ${ACTION_SIZE}px;
    margin-right: ${ACTION_SIZE * 0.5}px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${MAIN_COLOR};
    &:hover {
        background-color: ${SECONDARY_COLOR};
    }
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
