import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { boolean, object, string } from 'yup'

import Input from '../../Input'
import NoItem from '../../NoItem'
import Switch from '../../Switch'
import Select from '../../Select'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'

import useReducer from '../../../utils/store/useReducer'
import * as JourneyStore from '../../../utils/store/journey'
import useApi from '../../../utils/webservices/useApi'

const sex_options = [
    { key: 'FEMALE', label: 'Femme' },
    { key: 'MALE', label: 'Homme' },
]

interface Props {}

const userSchema = object({
    email: string().email("Le format de l'email n'est pas bon").required('Champ obligatoire'),
    firstName: string().required('Champ obligatoire'),
    lastName: string().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
    pseudo: string().required('Champ obligatoire'),
    sex: string().required('Champ obligatoire'),
    admin: boolean().required(),
})

const AddEditUser = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()
    const params = useParams()
    const navigate = useNavigate()

    const { selected } = useReducer(JourneyStore.store, (s) => s)

    const [status, setStatus] = React.useState<ScreenStatus>(params.userid ? 'loading' : 'fetched')
    const [deleting, setDeleting] = React.useState<boolean>(false)
    const [user, setUser] = React.useState<JourneyUser | undefined>()

    React.useEffect(() => {
        if (params.userid) {
            webservices.journeys
                .getUsers()
                .then((users) => {
                    const u = users.find((us) => us.id === params.userid)
                    setUser(u)
                    setStatus('fetched')
                })
                .catch(() => setStatus('error'))
        } else {
            setStatus('fetched')
        }
    }, [])

    const deleteUser = () => {
        if (user) {
            setDeleting(true)
            webservices.users
                .delete(user.id)
                .then(() => {
                    Alert.success("L'utilisateur a bien été supprimé !")
                    navigate('/admin/users')
                })
                .catch(() => Alert.error('Un problème est survenu..'))
                .finally(() => setDeleting(false))
        }
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!user && status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    user || {
                        email: '',
                        firstName: '',
                        lastName: '',
                        picture: '',
                        pseudo: '',
                        sex: '',
                        admin: false,
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    user
                        ? webservices.users
                              .update(user.id, {
                                  ...values,
                                  journeyId: selected?.id || '',
                                  firstName:
                                      values.firstName.charAt(0).toUpperCase() +
                                      values.firstName.slice(1).toLowerCase(),
                                  lastName: values.lastName.toUpperCase(),
                              })
                              .then(() => {
                                  Alert.success("L'utilisateur a bien été modifié !")
                                  navigate('/admin/users')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                        : webservices.journeys
                              .createUser({
                                  ...values,
                                  journeyId: selected?.id || '',
                                  firstName:
                                      values.firstName.charAt(0).toUpperCase() +
                                      values.firstName.slice(1).toLowerCase(),
                                  lastName: values.lastName.toUpperCase(),
                              })
                              .then(() => {
                                  Alert.success("L'utilisateur a bien été créé !")
                                  navigate('/admin/users')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                }}
                validationSchema={userSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Email"
                            name="email"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                            error={errors.email}
                        />
                        <Input
                            label="Prénom"
                            name="firstName"
                            type="text"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Prénom"
                            error={errors.firstName}
                        />
                        <Input
                            label="Nom"
                            name="lastName"
                            type="text"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Nom"
                            error={errors.lastName}
                        />
                        <Input
                            label="Pseudo"
                            name="pseudo"
                            type="text"
                            value={values.pseudo}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Pseudo"
                            error={errors.pseudo}
                        />
                        <Select
                            label="Sexe"
                            placeholder="Genre de l'utilisateur"
                            options={sex_options}
                            value={values.sex}
                            setValue={(v) => setFieldValue('sex', v)}
                            error={errors.sex}
                        />
                        <Switch
                            label="Admin"
                            value={values.admin}
                            setValue={(v) => setFieldValue('admin', v)}
                            size={25}
                            error={errors.admin}
                        />
                        <PictureInput
                            label="Photo"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                            error={errors.picture}
                        />
                        <Buttons>
                            {user && (
                                <Button
                                    onclick={deleteUser}
                                    status={deleting ? 'loading' : 'active'}
                                    label="Supprimer l'utilisateur"
                                    type="secondary"
                                />
                            )}
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={user ? "Modifier l'utilisateur" : "Créer l'utilisateur"}
                                type="primary"
                                position={!user ? 'RIGHT' : undefined}
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddEditUser

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
