import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import Select from '../../Select'
import NoItem from '../../NoItem'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import useReducer from '../../../utils/store/useReducer'
import * as JourneyStore from '../../../utils/store/journey'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

const contentSchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
    stepId: string().required('Champ obligatoire'),
})

const AddStepContent = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()
    const navigate = useNavigate()

    const { selected } = useReducer(JourneyStore.store, (s) => s)

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [steps, setSteps] = React.useState<Step[]>([])

    React.useEffect(() => {
        webservices.journeys
            .getSteps()
            .then(setSteps)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={{
                    title: '',
                    description: '',
                    picture: '',
                    stepId: '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                    webservices.steps
                        .createContent(values.stepId, {
                            ...values,
                            journeyId: selected?.id || '',
                        })
                        .then(() => {
                            Alert.success('Le contenu a bien été créé !')
                            navigate('/admin/stepContents')
                        })
                        .catch(() => Alert.error('Un problème est survenu..'))
                        .finally(() => setSubmitting(false))
                }}
                validationSchema={contentSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Titre du contenu"
                            name="title"
                            type="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Titre du contenu"
                            error={errors.title}
                        />
                        <Input
                            label="Description du contenu"
                            name="description"
                            type="long-text"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Description du contenu"
                            error={errors.description}
                        />
                        <PictureInput
                            label="Photo du contenu"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                            error={errors.picture}
                        />
                        <Select
                            label="Contenu d'étape"
                            placeholder="Sélectionner l'étape du contenu"
                            options={steps.map((s) => ({ key: s.id, label: s.title }))}
                            value={values.stepId}
                            setValue={(v) => setFieldValue('stepId', v)}
                            error={errors.stepId}
                        />
                        <Buttons>
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label="Créer le contenu"
                                type="primary"
                                position="RIGHT"
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddStepContent

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
