import * as React from 'react'

import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import Loading from '../../Loading'
import NoItem from '../../NoItem'
import Icon from '../../Icon'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

const ListJourneyContents = ({}: Props) => {
    const webservices = useApi()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [contents, setContents] = React.useState<ContentObject[]>([])

    React.useEffect(() => {
        webservices.journeys
            .getContents()
            .then(setContents)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    const renderItem = (item: ContentObject) => (
        <Line key={item.id} onClick={() => navigate('/admin/journeyContents/' + item.id)}>
            <Picture src={item.picture || '/login_background.jpeg'} />
            <Column>
                <Name>{item.title}</Name>
            </Column>
            <Action>
                <Icon name="forward" color="#FFF" />
            </Action>
        </Line>
    )

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (contents.length === 0 || status === 'error') {
        return <NoItem />
    }

    return contents.length === 0 ? <NoItem /> : contents.map(renderItem)
}

export default ListJourneyContents

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`

const Picture = styled.img`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Action = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const StartDate = styled.div`
    color: #777;
`
