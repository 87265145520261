import * as React from 'react'
import styled from 'styled-components'
import NoItem from '../NoItem'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../../utils/constant'
import { isMobile } from 'react-device-detect'

interface Props {
    teams: Team[]
}

const StepTeams = ({ teams }: Props) => {
    if (teams.length === 0) {
        return <NoItem />
    }

    return (
        <TeamListContainer>
            {teams.map((team, idx) => (
                <TeamCard key={team.id}>
                    <UserList>
                        {team.users.map((user) => (
                            <UserCard key={user.id}>
                                <UserPicture
                                    src={user.picture}
                                    alt={`${user.firstName} ${user.lastName}`}
                                />
                                <UserInfos className="user-info">
                                    <UserName className="user-name">{user.firstName}</UserName>
                                    <UserPseudo className="user-pseudo">
                                        {user.firstName}
                                    </UserPseudo>
                                </UserInfos>
                            </UserCard>
                        ))}
                    </UserList>
                </TeamCard>
            ))}
        </TeamListContainer>
    )
}

export default StepTeams
const TeamListContainer = styled.div`
    flex-direction: column;
    align-items: center;
    padding: 20px;
    display: flex;
`

const TeamCard = styled.div`
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 15px;
    color: #333;

    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    }

    flex-direction: column;
    margin: 10px 0;
    display: flex;
    width: 100%;
`

const UserList = styled.div`
    display: flex;
    justify-content: center;
    ${isMobile
        ? `
        flex-direction: column;
        align-items: center;
        justify
        `
        : 'flex-direction: row;'}
`

const UserCard = styled.div`
    background-color: #f0f4f8;
    color: #333;

    border-radius: 15px;
    transition:
        background-color 0.2s ease,
        transform 0.2s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: #e0e7ef;
        transform: scale(1.1);
    }

    img {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.2s ease;

        &:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }
    }

    width: 100%;
    display: flex;
    flex: 1;
    margin: 10px;
    padding: 10px;
`
const PICTURE_SIZE = 50
const UserPicture = styled.img`
    height: ${PICTURE_SIZE}px;
    width: ${PICTURE_SIZE}px;
    border-radius: 100%;
    object-fit: cover;
    ${OUTER_SHADOW}
`
const UserInfos = styled.div`
    flex: 1;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`
const UserName = styled.div`
    font-weight: 900;
    font-size: 18px;
    color: #000;
`
const UserPseudo = styled.div`
    font-weight: 500;
    font-size: 16px;
    color: #555;
`
