import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { array, number, object, string } from 'yup'

import Input from '../../Input'
import Select from '../../Select'
import NoItem from '../../NoItem'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'
import { useNavigate, useParams } from 'react-router-dom'
import NumberInput from '../../NumberInput'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../../../utils/constant'
import FormError from '../../FormError'
import ErrorLogger from '../../ErrorLogger'

interface Props {}

const bonusSchema = object({
    users: array().min(1, 'Choix obligatoire'),
    additionalTime: number().required('Champ obligatoire'),
})

const AddBonus = ({}: Props) => {
    const Alert = useAlert()
    const params = useParams()
    const webservices = useApi()
    const navigate = useNavigate()
    const stepId = params.stepId

    const [loading, setLoading] = React.useState<boolean>(true)
    const [users, setUsers] = React.useState<JourneyUser[]>([])

    if (!stepId) {
        return <NoItem />
    }

    React.useEffect(() => {
        webservices.journeys
            .getUsers()
            .then(setUsers)
            .catch(ErrorLogger)
            .finally(() => setLoading(false))
    }, [])

    return (
        <Container>
            {loading ? (
                <Loading type="transparent" />
            ) : (
                <Formik
                    initialValues={{ users: [], additionalTime: 0 }}
                    onSubmit={(values, { setSubmitting }) => {
                        webservices.steps
                            .createBonus(stepId, values)
                            .then(() => {
                                Alert.success('Le bonus a bien été créé !')
                                navigate('/admin/bonus')
                            })
                            .catch(() => Alert.error('Un problème est survenu..'))
                            .finally(() => setSubmitting(false))
                    }}
                    validationSchema={bonusSchema}
                    validateOnMount>
                    {({ values, isSubmitting, handleSubmit, setFieldValue, errors, isValid }) => (
                        <>
                            <UsersContainer>
                                {users.map((u) => {
                                    const userSelected = !!values.users.find((us) => us === u.id)
                                    return (
                                        <UserPicture
                                            src={u.picture}
                                            selected={userSelected}
                                            onClick={() =>
                                                userSelected
                                                    ? setFieldValue(
                                                          'users',
                                                          values.users.filter((us) => us !== u.id)
                                                      )
                                                    : setFieldValue('users', [
                                                          ...values.users,
                                                          u.id,
                                                      ])
                                            }
                                        />
                                    )
                                })}
                                <FormError error={errors.users as string} />
                            </UsersContainer>
                            <NumberInput
                                label="Bonus/Malus à attribuer (secondes)"
                                name="additionalTime"
                                value={values.additionalTime}
                                onChange={(v) => setFieldValue('additionalTime', v)}
                                error={errors.additionalTime}
                            />
                            <Buttons>
                                <Button
                                    onclick={handleSubmit}
                                    status={
                                        !isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'
                                    }
                                    label="Créer le bonus"
                                    type="primary"
                                    position="RIGHT"
                                />
                            </Buttons>
                        </>
                    )}
                </Formik>
            )}
        </Container>
    )
}

export default AddBonus

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
const UsersContainer = styled.div`
    flew-wrap: wrap;
`
const PICTURE_SIZE = 75
const PICTURE_MARGIN = 10
const UserPicture = styled.img<{ selected: boolean }>`
    object-fit: cover;
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    margin: ${PICTURE_MARGIN}px;
    border-radius: ${PICTURE_SIZE}px;

    border: 5px solid ${(props) => (props.selected ? SECONDARY_COLOR : '#FFF')};
    ${(props) => props.selected && OUTER_SHADOW}
`
