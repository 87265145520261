import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import Button from '../../Button'
import Loading from '../../Loading'

import { useAlert } from 'react-alert'
import { useNavigate, useParams } from 'react-router-dom'
import NoItem from '../../NoItem'
import Select from '../../Select'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'
import { isAfter } from 'date-fns'

interface Props {}

const stepSchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
    startCityId: string().required('Champ obligatoire'),
    endCityId: string().required('Champ obligatoire'),
})

const AddEditStep = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()
    const params = useParams()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>(params.stepId ? 'loading' : 'fetched')
    const [deleting, setDeleting] = React.useState<boolean>(false)

    const [step, setStep] = React.useState<Step>()
    const [cities, setCities] = React.useState<City[]>([])

    React.useEffect(() => {
        webservices.journeys
            .getCities()
            .then((c) => setCities(c.sort((a, b) => (isAfter(a.startDate, b.startDate) ? 1 : -1))))
            .catch(() => setStatus('error'))
            .finally(() => {
                params.stepId &&
                    webservices.steps
                        .get(params.stepId)
                        .then(setStep)
                        .then(() => setStatus('fetched'))
                        .catch(() => setStatus('error'))
            })
    }, [])

    const deleteStep = () => {
        if (step) {
            setDeleting(true)
            webservices.steps
                .delete(step.id)
                .then(() => {
                    Alert.success("L'étape a bien été supprimée !")
                    navigate('/admin/steps')
                })
                .catch(() => Alert.error('Un problème est survenu..'))
                .finally(() => setDeleting(false))
        }
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!step && status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    step || {
                        title: '',
                        description: '',
                        startDate: '',
                        endDate: '',
                        journeyId: '',
                        startCityId: '',
                        endCityId: '',
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    step
                        ? webservices.steps
                              .update(step.id, {
                                  ...values,
                                  title:
                                      values.title.charAt(0).toUpperCase() + values.title.slice(1),
                              })
                              .then(() => {
                                  Alert.success("L'étape a bien été modifiée !")
                                  navigate('/admin/steps')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                        : webservices.journeys
                              .createStep({
                                  ...values,
                                  title:
                                      values.title.charAt(0).toUpperCase() + values.title.slice(1),
                              })
                              .then(() => {
                                  Alert.success("L'étape a bien été créée !")
                                  navigate('/admin/steps')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                }}
                validationSchema={stepSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Titre de l'étape"
                            name="title"
                            type="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Titre de l'étape"
                            error={errors.title}
                        />
                        <Input
                            label="Description de l'étape"
                            name="description"
                            type="long-text"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Description de l'étape"
                            error={errors.description}
                        />
                        <Select
                            label="Ville de départ"
                            placeholder="Sélectionner la ville de départ"
                            options={cities.map((c) => ({ key: c.id, label: c.name }))}
                            value={values.startCityId}
                            setValue={(v) => setFieldValue('startCityId', v)}
                            error={errors.startCityId}
                        />
                        <Select
                            label="Ville d'arrivée"
                            placeholder="Sélectionner la ville d'arrivée"
                            options={cities.map((c) => ({ key: c.id, label: c.name }))}
                            value={values.endCityId}
                            setValue={(v) => setFieldValue('endCityId', v)}
                            error={errors.endCityId}
                        />
                        <Buttons>
                            {step && (
                                <Button
                                    onclick={deleteStep}
                                    status={deleting ? 'loading' : 'active'}
                                    label="Supprimer l'étape"
                                    type="secondary"
                                />
                            )}
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={step ? "Modifier l'étape" : "Créer l'étape"}
                                type="primary"
                                position={!step ? 'RIGHT' : undefined}
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddEditStep

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
