import * as React from 'react'

import styled from 'styled-components'

import { format, isAfter } from 'date-fns'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import useApi from '../../../utils/webservices/useApi'
import Loading from '../../Loading'
import NoItem from '../../NoItem'
import Icon from '../../Icon'

interface Props {}

const ListSteps = ({}: Props) => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')

    const [steps, setSteps] = React.useState<Step[]>([])
    const [cities, setCities] = React.useState<City[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        Promise.all([webservices.journeys.getSteps(), webservices.journeys.getCities()])
            .then(([s, c]) => {
                setSteps(
                    s.sort((a, b) => {
                        const AendCity = c.find((city) => city.id === a.endCityId)
                        const BendCity = c.find((city) => city.id === a.endCityId)
                        a.title.localeCompare(b.title)
                        return AendCity && BendCity
                            ? isAfter(AendCity.startDate, BendCity.startDate)
                                ? -1
                                : 1
                            : -1
                    })
                )
                setCities(c)
            })
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    const renderItem = (item: Step) => {
        const startCity = cities.find((c) => c.id === item.startCityId)
        const endCity = cities.find((c) => c.id === item.endCityId)

        const startStepName = startCity?.name
        const endStepName = endCity?.name
        const stepStartDate = endCity?.startDate

        return (
            <Line key={item.id} onClick={() => navigate('/admin/steps/' + item.id)}>
                <Column>
                    <Name>{item.title}</Name>
                    <Steps>
                        {startStepName} - {endStepName}
                    </Steps>
                    <Date>
                        {stepStartDate
                            ? format(stepStartDate, 'dd/MM/yyyy - HH:mm')
                            : 'Aucune date'}
                    </Date>
                </Column>
                {!isMobile && (
                    <Action>
                        <Icon name="forward" color="#FFF" />
                    </Action>
                )}
            </Line>
        )
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (steps.length === 0 || status === 'error') {
        return <NoItem />
    }

    return steps.map(renderItem)
}

export default ListSteps

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const LINE_PADDING = 10
const PICTURE_SIZE = 60

const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    min-height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;
    padding: ${LINE_PADDING}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Date = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #999;
`
const Description = styled.div`
    font-size: 16px;
    font-weight: 300;
    color: #999;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Action = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Steps = styled.div`
    font-size: 18px;
    font-weight: 900;
    color: #777;
`
