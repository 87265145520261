import * as React from 'react'

import styled from 'styled-components'

import { format, isAfter } from 'date-fns'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { MAIN_COLOR, OUTER_SHADOW } from '../../../utils/constant'

import Loading from '../../Loading'
import NoItem from '../../NoItem'
import Icon from '../../Icon'
import useApi from '../../../utils/webservices/useApi'

interface Props {}

const ListCities = ({}: Props) => {
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [cities, setCities] = React.useState<City[]>([])

    const navigate = useNavigate()

    React.useEffect(() => {
        webservices.journeys
            .getCities()
            .then((c) => setCities(c.sort((a, b) => (isAfter(a.startDate, b.startDate) ? 1 : -1))))
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    const renderItem = (item: City) => (
        <Line key={item.id} onClick={() => navigate('/admin/cities/' + item.id)}>
            <Picture src={item.picture} />
            <Column>
                <Name>{item.name}</Name>
                <StartDate>{format(item.startDate, 'dd/MM/yyyy HH:mm')}</StartDate>
            </Column>
            {!isMobile && (
                <Action>
                    <Icon name="forward" color="#FFF" />
                </Action>
            )}
        </Line>
    )

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!cities || status === 'error') {
        return <NoItem />
    }

    return cities.length === 0 ? <NoItem /> : cities.map(renderItem)
}

export default ListCities

const MOB_LINE_HEIGHT = 120
const WEB_LINE_HEIGHT = 100
const LINE_RADIUS = 10
const LINE_MARGIN = 5
const PICTURE_SIZE = 75

const Line = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    height: ${isMobile ? MOB_LINE_HEIGHT : WEB_LINE_HEIGHT}px;
    border-radius: ${LINE_RADIUS}px;
    margin: ${LINE_MARGIN}px;
    margin-bottom: ${3 * LINE_MARGIN}px;

    cursor: pointer;
    ${OUTER_SHADOW}

    &:hover {
        background-color: ${MAIN_COLOR};
        & > div {
            & > div {
                color: #fff;
            }
        }
    }
`

const Picture = styled.img`
    width: ${PICTURE_SIZE}px;
    height: ${PICTURE_SIZE}px;
    border-radius: ${PICTURE_SIZE}px;

    object-fit: cover;
    ${OUTER_SHADOW}
    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const Column = styled.div`
    display: flex;
    flex: 2;

    flex-direction: column;
    margin: 0 10px;
`
const Name = styled.div`
    font-size: 25px;
    font-weight: 700;
    color: #000;
`
const Action = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin: 0 10px;

    margin: 0 ${0.25 * PICTURE_SIZE}px;
`
const StartDate = styled.div`
    color: #777;
`
