import * as React from 'react'

import styled from 'styled-components'

import { motion } from 'framer-motion'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'

const LOADING_PATH = ['/load01.png', '/load02.png', '/load03.png']

const DOT_SIZE = 25
const DOT_STYLE = { margin: '0 10px' }
const DOT_SCALE = 1.25
const DOT_DURATION = 1.2
const DOT_NUMBER = LOADING_PATH.length

interface Props {
    type: 'colored' | 'transparent'
}

const Loading = ({ type }: Props) => {
    return (
        <Container type={type}>
            {LOADING_PATH.map((itm, idx) => (
                <motion.div
                    key={idx}
                    animate={{ scale: [1, DOT_SCALE, 1] }}
                    transition={{
                        duration: DOT_DURATION,
                        delay: (DOT_DURATION * idx) / DOT_NUMBER,
                        repeat: Infinity,
                    }}>
                    <LoadingImage src={itm} height={DOT_SIZE} width={DOT_SIZE} style={DOT_STYLE} />
                </motion.div>
            ))}
        </Container>
    )
}

export default Loading

const CONTAINER_SIZE = 100
const Container = styled.div<{ type: 'colored' | 'transparent' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: -webkit-fill-available;

    ${(props) =>
        props.type === 'colored'
            ? `
        background-color: ${SECONDARY_COLOR};
        width: ${CONTAINER_SIZE * 2}px;
        height: ${CONTAINER_SIZE}px;
        border-radius: 20px;
        margin: auto;
    `
            : `height: 100%;
    min-height: 200px;`}
`
const LoadingImage = styled.img<{ height: number; width: number }>`
    ${OUTER_SHADOW}
    padding: 3px;
    object-fit: contain;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    border: 3px solid ${SECONDARY_COLOR};
    border-radius: ${(props) => props.height}px;
`
