import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import Select from '../../Select'
import NoItem from '../../NoItem'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import useApi from '../../../utils/webservices/useApi'
import { useNavigate, useParams } from 'react-router-dom'
import DateInput from '../../DateInput'

interface Props {}

const challengeSchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
    stepId: string().required('Champ obligatoire'),
})

const AddEditChallenge = ({}: Props) => {
    const webservices = useApi()
    const Alert = useAlert()
    const params = useParams()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [deleting, setDeleting] = React.useState<boolean>(false)

    const [steps, setSteps] = React.useState<Step[]>([])
    const [challenge, setChallenge] = React.useState<Challenge | undefined>()

    React.useEffect(() => {
        webservices.journeys.getSteps().then((s) => setSteps(s))
        if (params.challengeId) {
            webservices.challenges
                .get(params.challengeId)
                .then(setChallenge)
                .then(() => setStatus('fetched'))
                .catch(() => setStatus('error'))
        } else {
            setStatus('fetched')
        }
    }, [])

    const deleteChallenge = () => {
        if (challenge) {
            setDeleting(true)
            webservices.challenges
                .delete(challenge.id)
                .then(() => {
                    Alert.success('Le challenge a bien été supprimé !')
                    navigate('/admin/challenges')
                })
                .catch(() => Alert.error('Un problème est survenu..'))
                .finally(() => setDeleting(false))
        }
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!challenge && status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={
                    challenge || {
                        title: '',
                        description: '',
                        stepId: '',
                        picture: '',
                        startDate: '2025-01-01T10:00:00.000Z',
                        endDate: '2025-01-01T10:00:00.000Z',
                    }
                }
                onSubmit={(values, { setSubmitting }) => {
                    challenge
                        ? webservices.challenges
                              .update(challenge.id, values)
                              .then(() => {
                                  Alert.success('Le challenge a bien été modifié !')
                                  navigate('/admin/challenges')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                        : webservices.steps
                              .createChallenge(values.stepId, values)
                              .then(() => {
                                  Alert.success('Le challenge a bien été créé !')
                                  navigate('/admin/challenges')
                              })
                              .catch(() => Alert.error('Un problème est survenu..'))
                              .finally(() => setSubmitting(false))
                }}
                validationSchema={challengeSchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Titre du challenge"
                            name="title"
                            type="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Titre du challenge"
                            error={errors.title}
                        />
                        <Input
                            label="Description du challenge"
                            name="description"
                            type="long-text"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Description du challenge"
                            error={errors.description}
                        />
                        <PictureInput
                            label="Photo du contenu"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                        />
                        <Select
                            label="Choix de l'étape"
                            placeholder="Sélectionner l'étape du challenge"
                            options={steps.map((s) => ({ key: s.id, label: s.title }))}
                            value={values.stepId}
                            setValue={(v) => setFieldValue('stepId', v)}
                        />
                        {/* <DateInput
                            label="Début du challenge"
                            value={values.startDate}
                            onChange={(v) => setFieldValue('startDate', v)}
                            error={errors.startDate}
                        />
                        <DateInput
                            label="Fin du challenge"
                            value={values.endDate}
                            onChange={(v) => setFieldValue('endDate', v)}
                            error={errors.endDate}
                        /> */}
                        <Buttons>
                            {challenge && (
                                <Button
                                    onclick={deleteChallenge}
                                    status={deleting ? 'loading' : 'active'}
                                    label="Supprimer le challenge"
                                    type="secondary"
                                />
                            )}
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={challenge ? 'Modifier le contenu' : 'Créer le contenu'}
                                type="primary"
                                position={!challenge ? 'RIGHT' : undefined}
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default AddEditChallenge

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
