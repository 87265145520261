import { createEvent, createStore } from 'effector'

export const actions = {
    setToken: createEvent<TokenResponse>('setToken'),
    setRefresh: createEvent<string>('setRefresh'),
    setUser: createEvent<JourneyUser>('setUser'),
    setMe: createEvent<User>('setMe'),
    logout: createEvent('logout'),
}

const initialState: AccountState = {
    tokenDate: undefined,
    refresh: undefined,
    token: undefined,
    user: undefined,
    me: undefined,
}

export const store = createStore<AccountState>(initialState, {
    name: 'account',
})
    .on(actions.setToken, (s, token) => ({ ...s, token, tokenDate: new Date().toLocaleString() }))
    .on(actions.setRefresh, (s, refresh) => ({ ...s, refresh }))
    .on(actions.setUser, (s, user) => ({ ...s, user }))
    .on(actions.setMe, (s, me) => ({ ...s, me }))
    .on(actions.logout, () => ({ ...initialState }))
