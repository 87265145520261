import * as React from 'react'

import useApi from '../utils/webservices/useApi'
import { useParams } from 'react-router-dom'

import { isAfter } from 'date-fns'
import styled from 'styled-components'
import { OUTER_SHADOW, SECONDARY_COLOR } from '../utils/constant'

import NoItem from '../components/NoItem'
import Loading from '../components/Loading'

import StepInfo from '../components/step/StepInfo'
import StepRanking from '../components/step/StepRanking'
import StepArrivals from '../components/step/StepArrivals'
import StepLocation from '../components/step/StepLocation'
import StepChallenges from '../components/step/StepChallenges'
import StepBonus from '../components/step/StepBonus'
import StepTeams from '../components/step/StepTeams'

const ACTION_ITEMS = [
    { key: 'step', label: 'Résumé' },
    { key: 'info', label: 'Informations' },
    { key: 'teams', label: 'Équipes' },
    { key: 'challenges', label: 'Challenges' },
    { key: 'location', label: 'Objectif' },
    { key: 'arrivals', label: 'Arrivées' },
    { key: 'ranking', label: 'Classement' },
    { key: 'bonus', label: 'Bonus' },
]

const Step = () => {
    const params = useParams()
    const webservices = useApi()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [action, setAction] = React.useState<{ key: string; label: string }>(ACTION_ITEMS[0])

    const [step, setStep] = React.useState<Step | undefined>()
    const [start, setStart] = React.useState<City | undefined>()
    const [end, setEnd] = React.useState<City | undefined>()

    const [users, setUsers] = React.useState<JourneyUser[]>([])
    const [contents, setContents] = React.useState<ContentObject[]>([])
    const [challenges, setChallenges] = React.useState<Challenge[]>([])
    const [rankings, setRankings] = React.useState<Ranking[]>([])
    const [teams, setTeams] = React.useState<Team[]>([])
    const [bonus, setBonus] = React.useState<Bonus[]>([])
    const [arrivals, setArrivals] = React.useState<Arrival[]>([])

    const fetchData = () => {
        if (params.stepId) {
            webservices.steps
                .get(params.stepId)
                .then((st) => {
                    Promise.all([
                        webservices.steps.getRanking(st.id),
                        webservices.steps.getArrivals(st.id),
                    ]).then(([r, a]) => {
                        setRankings(r)
                        setArrivals(
                            a.sort((ar, br) => (isAfter(ar.arrivalDate, br.arrivalDate) ? 1 : -1))
                        )
                        setStatus('fetched')
                    })
                })
                .catch(() => setStatus('error'))
        }
    }

    React.useEffect(() => {
        if (params.stepId) {
            webservices.steps
                .get(params.stepId)
                .then((st) => {
                    Promise.all([
                        webservices.cities.get(st.startCityId),
                        webservices.cities.get(st.endCityId),
                        webservices.steps.getChallenges(st.id),
                        webservices.steps.getContents(st.id),
                        webservices.steps.getRanking(st.id),
                        webservices.steps.getTeams(st.id),
                        webservices.steps.getBonus(st.id),
                        webservices.steps.getArrivals(st.id),
                        webservices.journeys.getUsers(),
                    ]).then(([s, e, ch, co, r, t, b, a, us]) => {
                        setStep(st)
                        setStart(s)
                        setEnd(e)
                        setChallenges(ch)
                        setContents(co)
                        setRankings(r)
                        setTeams(t)
                        setBonus(b)
                        setArrivals(
                            a.sort((ar, br) => (isAfter(ar.arrivalDate, br.arrivalDate) ? 1 : -1))
                        )
                        setUsers(us)
                        setStatus('fetched')
                    })
                })
                .catch(() => setStatus('error'))
        } else {
            setStatus('error')
        }
    }, [])

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!step || !start || !end) {
        return <NoItem />
    }

    return (
        <StepContainer>
            <ActionSelector>
                {ACTION_ITEMS.map((a) => (
                    <ActionItem
                        key={a.key}
                        selected={a.key === action.key}
                        onClick={() => setAction(a)}>
                        {a.label}
                    </ActionItem>
                ))}
            </ActionSelector>
            {action.key === 'step' ? (
                <Container>
                    <Title>{step.title}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: step.description }} />
                    <CitySection>
                        <CityCard>
                            <CityImage src={start.picture} alt={start.name} />
                            <CityInfo>
                                <CityName>{start.name}</CityName>
                                <CityLabel>Ville de départ</CityLabel>
                            </CityInfo>
                        </CityCard>

                        <CityCard>
                            <CityImage src={end.picture} alt={end.name} />
                            <CityInfo>
                                <CityName>{end.name}</CityName>
                                <CityLabel>Ville d'arrivée</CityLabel>
                            </CityInfo>
                        </CityCard>
                    </CitySection>
                </Container>
            ) : action.key === 'info' ? (
                <StepInfo contents={contents} />
            ) : action.key === 'location' ? (
                <StepLocation step={step} end={end} fetchData={fetchData} arrivals={arrivals} />
            ) : action.key === 'challenges' ? (
                <StepChallenges challenges={challenges} />
            ) : action.key === 'ranking' ? (
                <StepRanking rankings={rankings} />
            ) : action.key === 'arrivals' ? (
                <StepArrivals arrivals={arrivals} end={end} />
            ) : action.key === 'bonus' ? (
                <StepBonus bonuses={bonus} users={users} />
            ) : action.key === 'teams' ? (
                <StepTeams teams={teams} />
            ) : null}
        </StepContainer>
    )
}

export default Step

const StepContainer = styled.div``
const ActionSelector = styled.div`
    ${OUTER_SHADOW}
    z-index: 10000;
    display: flex;
    position: sticky;
    top: 10px;
    margin: 0 10px;
    flex-direction: row;
    border-radius: 5px;
    background-color: #eee;

    padding: 5px;
    overflow: scroll;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    justify-content: space-between;
`
const ActionItem = styled.div<{ selected?: boolean }>`
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    ${(props) =>
        props.selected &&
        `background-color: ${SECONDARY_COLOR};
    color: #fff;
    font-weight: 900;`}
`
const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    height: -webkit-fill-available;
    @media (max-width: 768px) {
        padding: 15px;
    }
`

const Title = styled.h1`
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
`

const Description = styled.p`
    font-size: 1.2rem;
    color: #555;
    text-align: center;
    margin-bottom: 25px;
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`

const CitySection = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`
const CityCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    @media (max-width: 768px) {
        max-width: 100%;
        width: 80%;
    }
`

const CityImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
`

const CityInfo = styled.div`
    text-align: center;
    margin-top: 10px;
`

const CityName = styled.h2`
    font-size: 1.4rem;
    color: #333;
`

const CityLabel = styled.p`
    font-size: 1rem;
    color: #888;
`
