import * as React from 'react'

import { styled } from 'styled-components'

import { isMobile } from 'react-device-detect'
import { MAIN_COLOR, SECONDARY_COLOR } from '../utils/constant'
import { CMPNT_HEIGHT, CMPNT_RADIUS, OUTER_SHADOW } from '../utils/constant'

import Icon from './Icon'
import { ThreeDots } from 'react-loader-spinner'

interface Props {
    label: string
    onclick: () => void
    double?: boolean
    status: ButtonStatus
    type: ButtonType
    righticon?: IconName
    position?: 'LEFT' | 'RIGHT'
}

const FONT_COLOR = '#FFFFFF'
const PRIMARY_COLOR = MAIN_COLOR
const PRIMARY_DISABLED_COLOR = '#777777'
const SECONDARY_DISABLED_COLOR = '#000000'
const DISABLED_COLOR = '#FFFFFF'
const BACKGROUND_OPACITY = 'FF'

const Button = ({ label, onclick, status, righticon, type, position, double }: Props) => {
    return (
        <Container
            type={type}
            status={status}
            onClick={() => {
                if (!double) {
                    onclick()
                }
            }}
            onDoubleClick={() => {
                if (double) {
                    onclick()
                }
            }}
            position={position}
            disabled={status === 'disabled'}>
            {status === 'loading' ? (
                <ThreeDots
                    visible={true}
                    height={CMPNT_HEIGHT * 0.2}
                    color={PRIMARY_COLOR}
                    ariaLabel="three-dots-loading"
                />
            ) : (
                <Label disabled={status === 'disabled'} type={type}>
                    {label}
                </Label>
            )}
            {status !== 'loading' && (
                <IconContainer disabled={status === 'disabled'} type={type}>
                    <Icon
                        name={righticon || 'forward'}
                        color={status === 'disabled' ? DISABLED_COLOR : FONT_COLOR}
                        size={20}
                    />
                </IconContainer>
            )}
        </Container>
    )
}

export default Button

const Container = styled.div<{
    disabled: boolean
    type: ButtonType
    status: ButtonStatus
    position?: 'LEFT' | 'RIGHT'
}>`
    display: flex;
    align-items: center;
    justify-content: space-around;

    ${OUTER_SHADOW}
    ${(props) => props.position === 'LEFT' && 'margin-right: auto;'}
    ${(props) => props.position === 'RIGHT' && 'margin-left: auto;'}
    height: ${CMPNT_HEIGHT}px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0px;

    border-radius: ${CMPNT_RADIUS}px;
    border: 2px solid
        ${(props) =>
            props.type === 'primary'
                ? props.disabled
                    ? PRIMARY_DISABLED_COLOR
                    : PRIMARY_COLOR
                : props.disabled
                  ? SECONDARY_DISABLED_COLOR
                  : SECONDARY_COLOR}${BACKGROUND_OPACITY};
    background-color: ${(props) =>
        props.type === 'primary'
            ? props.disabled
                ? PRIMARY_DISABLED_COLOR + BACKGROUND_OPACITY
                : FONT_COLOR
            : props.disabled
              ? SECONDARY_DISABLED_COLOR + BACKGROUND_OPACITY
              : FONT_COLOR};

    &:hover {
        ${(props) =>
            props.status !== 'loading' &&
            `background-color:
            ${
                props.type === 'primary'
                    ? props.disabled
                        ? PRIMARY_DISABLED_COLOR
                        : PRIMARY_COLOR
                    : props.disabled
                      ? SECONDARY_DISABLED_COLOR
                      : SECONDARY_COLOR
            }${BACKGROUND_OPACITY};`}
        & > h3 {
            ${(props) =>
                props.status !== 'loading' &&
                `color: ${props.disabled ? DISABLED_COLOR : FONT_COLOR};`}
        }
        & > div {
            background-color: transparent;
        }
    }

    ${(props) => props.status === 'active' && 'cursor: pointer;'}
`
const Label = styled.h3<{
    disabled: boolean
    type: ButtonType
}>`
    margin: 0 20px;
    padding: 0;

    font-size: ${isMobile ? 16 : 18}px;
    font-weight: 900;
    font-family: Nunito;
    // text-transform: uppercase;

    color: ${(props) =>
        props.type === 'primary'
            ? props.disabled
                ? DISABLED_COLOR
                : PRIMARY_COLOR
            : props.disabled
              ? DISABLED_COLOR
              : SECONDARY_COLOR};
`
const IconContainer = styled.div<{
    disabled: boolean
    type: ButtonType
}>`
    width: 30px;
    height: 100%;
    display: flex;
    padding: 0 5px 0 0;
    align-items: center;
    justify-content: flex-end;
    border-radius: 100px 0 0 100px;
    background-color: ${(props) =>
        props.disabled ? 'none' : props.type === 'primary' ? PRIMARY_COLOR : SECONDARY_COLOR};
`
