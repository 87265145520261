import { AxiosInstance } from 'axios'

const base_url = 'https://toxic-mercie-barystop-1104c7c1.koyeb.app'

const webservices = (Axios: AxiosInstance, journeyId?: string): WebServicesData => ({
    login: (code: string): Promise<TokenResponseWithRefresh> =>
        Axios.post(base_url + '/login', { code, redirectUri: window.location.origin }).then(
            (res) => res.data
        ),
    journeys: {
        list: (): Promise<Journey[]> => Axios.get(base_url + '/journeys').then((res) => res.data),
        create: (travel: CreateJourneyRequest): Promise<Journey> =>
            Axios.post(base_url + '/journeys', travel).then((res) => res.data),
        get: (): Promise<Journey> =>
            Axios.get(base_url + `/journeys/${journeyId}`).then((res) => res.data),
        update: (journey: CreateJourneyRequest): Promise<Journey> =>
            Axios.put(base_url + `/journeys/${journeyId}`, journey).then((res) => res.data),
        delete: () => Axios.delete(base_url + `/journeys/${journeyId}`),
        getUsers: (): Promise<JourneyUser[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/users`).then((res) => res.data),
        createUser: (journeyUser: CreateUserRequest): Promise<JourneyUser> =>
            Axios.post(base_url + `/journeys/${journeyId}/users`, journeyUser).then(
                (res) => res.data
            ),
        generateTeams: () => Axios.post(base_url + `/journeys/${journeyId}/regenerateTeams`),
        getAllTeams: (): Promise<Team[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/teams`).then((res) => res.data),
        getAvailableCities: (): Promise<CitiesAndTotal> =>
            Axios.get(base_url + `/journeys/${journeyId}/cities/availables`).then(
                (res) => res.data
            ),
        getAvailableSteps: (): Promise<Step[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/steps/availables`).then((res) => res.data),
        getCities: (): Promise<City[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/cities`).then((res) => res.data),
        createCity: (city: CreateCityRequest): Promise<City> =>
            Axios.post(base_url + `/journeys/${journeyId}/cities`, city).then((res) => res.data),
        getSteps: (): Promise<Step[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/steps`).then((res) => res.data),
        createStep: (step: CreateStepRequest): Promise<Step> =>
            Axios.post(base_url + `/journeys/${journeyId}/steps`, step).then((res) => res.data),
        getContents: (): Promise<ContentObject[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/contents`).then((res) => res.data),
        createContent: (content: CreateContentRequest): Promise<ContentObject> =>
            Axios.post(base_url + `/journeys/${journeyId}/contents`, content).then(
                (res) => res.data
            ),
        getRanking: (): Promise<StepRanking[]> =>
            Axios.get(base_url + `/journeys/${journeyId}/ranking`).then((res) => res.data),
    },
    users: {
        update: (userId: string, user: CreateUserRequest): Promise<JourneyUser> =>
            Axios.put(base_url + `/users/${userId}`, user).then((res) => res.data),
        delete: (userId: string) =>
            Axios.delete(base_url + `/users/${userId}`, { data: { journeyId } }),
        getJourneys: (): Promise<Journey[]> =>
            Axios.get(base_url + '/users/journeys').then((res) => res.data),
        me: (): Promise<User> => Axios.get(base_url + '/users/connected').then((res) => res.data),
        meOnJourney: (journeyId: string): Promise<JourneyUser> =>
            Axios.get(base_url + `/users/connected/journeys/${journeyId}`).then((res) => res.data),
    },
    cities: {
        get: (cityId: string): Promise<City> =>
            Axios.get(base_url + `/cities/${cityId}`).then((res) => res.data),
        update: (cityId: string, city: CreateCityRequest): Promise<City> =>
            Axios.put(base_url + `/cities/${cityId}`, city).then((res) => res.data),
        delete: (cityId: string) => Axios.delete(base_url + `/cities/${cityId}`),
    },
    steps: {
        get: (stepId: string): Promise<Step> =>
            Axios.get(base_url + `/steps/${stepId}`).then((res) => res.data),
        update: (stepId: string, step: CreateStepRequest): Promise<Step> =>
            Axios.put(base_url + `/steps/${stepId}`, step).then((res) => res.data),
        delete: (stepId: string) => Axios.delete(base_url + `/steps/${stepId}`),
        getContents: (stepId: string): Promise<ContentObject[]> =>
            Axios.get(base_url + `/steps/${stepId}/contents`).then((res) => res.data),
        createContent: (stepId: string, content: CreateContentRequest) =>
            Axios.post(base_url + `/steps/${stepId}/contents`, content).then((res) => res.data),
        getChallenges: (stepId: string): Promise<Challenge[]> =>
            Axios.get(base_url + `/steps/${stepId}/challenges`).then((res) => res.data),
        createChallenge: (stepId: string, challenge: CreateChallengeRequest): Promise<Challenge> =>
            Axios.post(base_url + `/steps/${stepId}/challenges`, challenge).then((res) => res.data),
        getTeams: (stepId: string): Promise<Team[]> =>
            Axios.get(base_url + `/steps/${stepId}/teams`).then((res) => res.data),
        createTeam: (stepId: string, team: CreateTeamRequest) =>
            Axios.post(base_url + `/steps/${stepId}/teams`, team).then((res) => res.data),
        getArrivals: (stepId: string): Promise<Arrival[]> =>
            Axios.get(base_url + `/steps/${stepId}/arrivals`).then((res) => res.data),
        createArrival: (stepId: string, arrival: CreateArrivalRequest): Promise<Arrival> =>
            Axios.post(base_url + `/steps/${stepId}/arrivals`, arrival).then((res) => res.data),
        getBonus: (stepId: string): Promise<Bonus[]> =>
            Axios.get(base_url + `/steps/${stepId}/bonus`).then((res) => res.data),
        createBonus: (stepId: string, bonus: CreateBonusRequest): Promise<Bonus> =>
            Axios.post(base_url + `/steps/${stepId}/bonus`, bonus).then((res) => res.data),
        getRanking: (stepId: string): Promise<Ranking[]> =>
            Axios.get(base_url + `/steps/${stepId}/ranking`).then((res) => res.data),
    },
    contents: {
        get: (contentId: string): Promise<ContentObject> =>
            Axios.get(base_url + `/contents/${contentId}`).then((res) => res.data),
        update: (contentId: string, content: CreateContentRequest): Promise<ContentObject> =>
            Axios.put(base_url + `/contents/${contentId}`, content).then((res) => res.data),
        delete: (contentId: string) => Axios.delete(base_url + `/contents/${contentId}`),
    },
    challenges: {
        get: (challengeId: string): Promise<Challenge> =>
            Axios.get(base_url + `/challenges/${challengeId}`).then((res) => res.data),
        update: (challengeId: string, challenge: CreateChallengeRequest): Promise<Challenge> =>
            Axios.put(base_url + `/challenges/${challengeId}`, challenge).then((res) => res.data),
        delete: (challengeId: string) => Axios.delete(base_url + `/challenges/${challengeId}`),
        getAllResponses: (challengeId: string): Promise<ChallengeResponse[]> =>
            Axios.get(base_url + `/challenges/${challengeId}/challengeResponses`).then(
                (res) => res.data
            ),
        getMyResponses: (challengeId: string): Promise<ChallengeResponse[]> =>
            Axios.get(base_url + `/challenges/${challengeId}/myChallengeResponses`).then(
                (res) => res.data
            ),
        createResponse: (
            challengeId: string,
            response: CreateChallengeResponseRequest
        ): Promise<ChallengeResponse> =>
            Axios.post(base_url + `/challenges/${challengeId}/challengeResponses`, response).then(
                (res) => res.data
            ),
    },
    responses: {
        get: (responseId: string): Promise<ChallengeResponse> =>
            Axios.get(base_url + `/challengeResponses/${responseId}`).then((res) => res.data),
        update: (
            responseId: string,
            response: CreateChallengeResponseRequest
        ): Promise<ChallengeResponse> =>
            Axios.put(base_url + `/challengeResponses/${responseId}`, response).then(
                (res) => res.data
            ),
        delete: (responseId: string) =>
            Axios.delete(base_url + `/challengeResponses/${responseId}`),
    },
    bonus: {
        get: (bonusId: string): Promise<Bonus> =>
            Axios.get(base_url + `/bonus/${bonusId}`).then((res) => res.data),
        update: (bonusId: string, bonus: CreateBonusRequest): Promise<Bonus> =>
            Axios.put(base_url + `/bonus/${bonusId}`, bonus).then((res) => res.data),
        delete: (bonusId: string) => Axios.delete(base_url + `/bonus/${bonusId}`),
    },
    teams: {
        get: (teamId: string): Promise<Team> =>
            Axios.get(base_url + `/teams/${teamId}`).then((res) => res.data),
        update: (teamId: string, team: CreateTeamRequest): Promise<Team> =>
            Axios.put(base_url + `/teams/${teamId}`, team).then((res) => res.data),
        delete: (teamId: string) => Axios.delete(base_url + `/teams/${teamId}`),
        associateUser: (teamId: string, userId: string) =>
            Axios.post(base_url + `/teams/${teamId}/users/${userId}`),
        unassociateUser: (teamId: string, userId: string) =>
            Axios.delete(base_url + `/teams/${teamId}/users/${userId}`),
    },
})

export { base_url }
export default webservices
