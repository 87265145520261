import * as React from 'react'

import { styled } from 'styled-components'

import { CMPNT_HEIGHT, INNER_SHADOW } from '../utils/constant'
import FormLabel from './FormLabel'
import FormError from './FormError'
import InputNumber from 'rc-input-number'

interface Props {
    name: string
    value: number | undefined
    onChange: ((value: number | null) => void) | undefined
    label?: string
    error?: string
}

const NumberInput = ({ name, value, onChange, label, error }: Props) => {
    return (
        <ComponentContainer>
            {label && <FormLabel label={label} />}
            {/* @ts-ignore */}
            <InputContainer defaultValue={value} onChange={onChange} />
            <FormError error={error} />
        </ComponentContainer>
    )
}

export default NumberInput

const ComponentContainer = styled.div`
    margin: 5px 0;
    display: flex;
    flex-direction: column;
`
const InputContainer = styled(InputNumber)`
    .rc-input-number-input {
        border: none;
        outline: none;
        padding: 0 20px;
        background-color: #eee;
        ${INNER_SHADOW}

        border-radius: ${CMPNT_HEIGHT * 0.25}px;
        width: -webkit-fill-available;
        height: ${CMPNT_HEIGHT}px;
        cursor: pointer;

        color: #000;
        font-size: 16px;
        font-family: Nunito;
    }
`
