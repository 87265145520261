import React from 'react'
import styled from 'styled-components'
import { formatTime } from '../../utils/constant'
import NoItem from '../NoItem'

interface Props {
    users: JourneyUser[]
    bonuses: Bonus[]
}
const StepBonus = ({ users, bonuses }: Props) => {
    const getUserById = (userId: string) => users.find((user) => user.id === userId)

    if (users.length === 0 || bonuses.length === 0) {
        return <NoItem />
    }

    return (
        <BonusListContainer>
            {bonuses.map((bonus) => {
                const user = getUserById(bonus.userId)
                return (
                    <BonusCard key={bonus.id}>
                        <UserDetails>
                            <img src={user?.picture} alt={user?.firstName} />
                            <div className="user-info">
                                <div className="user-pseudo">{user?.pseudo}</div>
                                <div className="user-name">
                                    {user?.firstName} {user?.lastName}
                                </div>
                            </div>
                        </UserDetails>
                        <BonusTime isPositive={bonus.additionalTime >= 0}>
                            {formatTime(bonus.additionalTime)}
                        </BonusTime>
                    </BonusCard>
                )
            })}
        </BonusListContainer>
    )
}

export default StepBonus

const BonusListContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const BonusCard = styled.div`
    background-color: #f7f7f7;
    border-radius: 12px;
    padding: 15px;
    margin: 10px 0;
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const UserDetails = styled.div`
    display: flex;
    align-items: center;

    img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 15px;
        object-fit: cover;
    }

    .user-info {
        display: flex;
        flex-direction: column;
    }

    .user-pseudo {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .user-name {
        font-size: 0.9rem;
        color: #666;
    }
`

const BonusTime = styled.div<{ isPositive: boolean }>`
    font-size: 1.5rem;
    color: ${({ isPositive }) =>
        isPositive ? '#e74c3c' : '#2ecc71'}; // Rouge pour malus, vert pour bonus
    font-weight: bold;

    @media (max-width: 768px) {
        margin-top: 10px;
    }
`
