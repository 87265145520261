import * as React from 'react'

import styled from 'styled-components'

import { Formik } from 'formik'
import { object, string } from 'yup'

import Input from '../../Input'
import NoItem from '../../NoItem'
import Button from '../../Button'
import Loading from '../../Loading'
import PictureInput from '../../PictureInput'

import { useAlert } from 'react-alert'
import { isMobile } from 'react-device-detect'
import { logout } from '../../../utils/store/store'
import { useNavigate, useParams } from 'react-router-dom'
import useApi from '../../../utils/webservices/useApi'
import DateInput from '../../DateInput'

interface Props {}

const journeySchema = object({
    title: string().required('Champ obligatoire'),
    description: string().required('Champ obligatoire'),
    picture: string().required('Champ obligatoire'),
})

const EditJourney = ({}: Props) => {
    const webservices = useApi()

    const Alert = useAlert()
    const params = useParams()
    const navigate = useNavigate()

    const [status, setStatus] = React.useState<ScreenStatus>('loading')
    const [journey, setJourney] = React.useState<Journey | undefined>()
    const [deleting, setDeleting] = React.useState<boolean>(false)

    React.useEffect(() => {
        webservices.journeys
            .get()
            .then(setJourney)
            .then(() => setStatus('fetched'))
            .catch(() => setStatus('error'))
    }, [])

    const deleteJourney = () => {
        setDeleting(true)
        webservices.journeys
            .delete()
            .then(() => {
                Alert.success('Le voyage a bien été supprimé !')
                logout()
                navigate('/')
            })
            .catch(() => Alert.error('Un problème est survenu..'))
            .finally(() => setDeleting(false))
    }

    if (status === 'loading') {
        return <Loading type="transparent" />
    }

    if (!journey || status === 'error') {
        return <NoItem />
    }

    return (
        <Container>
            <Formik
                initialValues={journey}
                onSubmit={(values, { setSubmitting }) => {
                    webservices.journeys
                        .update(values)
                        .then(() => {
                            Alert.success('Le voyage a bien été modifié !')
                            navigate('/admin')
                        })
                        .catch(() => Alert.error('Un problème est survenu..'))
                        .finally(() => setSubmitting(false))
                }}
                validationSchema={journeySchema}
                validateOnMount>
                {({
                    values,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    errors,
                    isValid,
                }) => (
                    <>
                        <Input
                            label="Titre du voyage"
                            name="title"
                            type="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Titre du voyage"
                            error={errors.title}
                        />
                        <Input
                            label="Description du voyage"
                            name="description"
                            type="long-text"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Description du voyage"
                            error={errors.description}
                        />
                        <PictureInput
                            label="Photo du voyage"
                            value={values.picture}
                            setValue={(v) => setFieldValue('picture', v)}
                            error={errors.picture}
                        />
                        <DateInput
                            label="Début du voyage"
                            value={values.startDate}
                            onChange={(v) => setFieldValue('startDate', v)}
                            error={errors.startDate}
                        />
                        <DateInput
                            label="Fin du voyage"
                            value={values.endDate}
                            onChange={(v) => setFieldValue('endDate', v)}
                            error={errors.endDate}
                        />
                        <Buttons>
                            <Button
                                onclick={deleteJourney}
                                status={deleting ? 'loading' : 'active'}
                                label="Supprimer le voyage"
                                type="secondary"
                            />
                            <Button
                                onclick={handleSubmit}
                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                label={'Modifier le voyage'}
                                type="primary"
                            />
                        </Buttons>
                    </>
                )}
            </Formik>
        </Container>
    )
}

export default EditJourney

const Container = styled.div`
    padding: 0 20px;
`
const Buttons = styled.div`
    display: flex;
    ${isMobile
        ? `flex-direction: column; align-items: center;
    justify-content: space-between;`
        : `align-items: center;
    justify-content: space-between;`}
`
