import * as React from 'react'

import { CiCircleQuestion } from 'react-icons/ci'
import { AiFillEnvironment } from 'react-icons/ai'
import { PiRankingFill } from 'react-icons/pi'
import { TbFlagQuestion } from 'react-icons/tb'
import { RiMenu5Fill } from 'react-icons/ri'
import { ImStatsDots } from 'react-icons/im'
import { FaUserAstronaut } from 'react-icons/fa6'
import { FaTachometerAlt } from 'react-icons/fa'
import { IoArrowBack } from 'react-icons/io5'
import { IoArrowForward } from 'react-icons/io5'
import { MdModeOfTravel } from 'react-icons/md'
import { RiAdminLine } from 'react-icons/ri'
import { FaUsers } from 'react-icons/fa'
import { FaTreeCity } from 'react-icons/fa6'
import { MdOutlineTravelExplore } from 'react-icons/md'
import { IoFootsteps } from 'react-icons/io5'
import { PiGameControllerBold } from 'react-icons/pi'
import { SiContentful } from 'react-icons/si'
import { BiSolidGame } from 'react-icons/bi'
import { RiLogoutCircleLine } from 'react-icons/ri'
import { MdAdd } from 'react-icons/md'
import { FaChevronDown } from 'react-icons/fa6'
import { MdDelete } from 'react-icons/md'
import { IoMdCalendar } from 'react-icons/io'
import { FaPenToSquare } from 'react-icons/fa6'
import { FaInfoCircle } from 'react-icons/fa'
import { IoHome } from 'react-icons/io5'

interface Props {
    name?: IconName
    size?: number
    color?: string
}

const Icon = ({ name, size = 25, color = '#000' }: Props) => {
    switch (name) {
        case 'back':
            return <IoArrowBack size={size} color={color} />
        case 'forward':
            return <IoArrowForward size={size} color={color} />
        case 'home2':
            return <FaTachometerAlt size={size} color={color} />
        case 'location':
            return <AiFillEnvironment size={size} color={color} />
        case 'travel':
            return <MdModeOfTravel size={size} color={color} />
        case 'ranking':
            return <PiRankingFill size={size} color={color} />
        case 'flag':
            return <TbFlagQuestion size={size} color={color} />
        case 'menu':
            return <RiMenu5Fill size={size} color={color} />
        case 'stats':
            return <ImStatsDots size={size} color={color} />
        case 'account':
            return <FaUserAstronaut size={size} color={color} />
        case 'admin':
            return <RiAdminLine size={size} color={color} />
        case 'users':
            return <FaUsers size={size} color={color} />
        case 'city':
            return <FaTreeCity size={size} color={color} />
        case 'travels':
            return <MdOutlineTravelExplore size={size} color={color} />
        case 'steps':
            return <IoFootsteps size={size} color={color} />
        case 'games':
            return <PiGameControllerBold size={size} color={color} />
        case 'challenge':
            return <BiSolidGame size={size} color={color} />
        case 'content':
            return <SiContentful size={size} color={color} />
        case 'logout':
            return <RiLogoutCircleLine size={size} color={color} />
        case 'add':
            return <MdAdd size={size} color={color} />
        case 'chevron-down':
            return <FaChevronDown size={size} color={color} />
        case 'delete':
            return <MdDelete size={size} color={color} />
        case 'calendar':
            return <IoMdCalendar size={size} color={color} />
        case 'pen':
            return <FaPenToSquare size={size} color={color} />
        case 'info':
            return <FaInfoCircle size={size} color={color} />
        case 'home':
            return <IoHome size={size} color={color} />

        default:
            return <CiCircleQuestion size={size} color={color} />
    }
}

export default Icon
